import React from "react";

//components
import DettLandingPageHeader from "../includes/header/DettLandingPageHeader";
import ProductSpotlight from "./product-single-page/ProductSpotlight";
import SimilarProducts from "./product-single-page/SimilarProducts";

export default function ProductSinglepage() {
    return (
        <>
            <DettLandingPageHeader />
            <ProductSpotlight />
            <SimilarProducts />
        </>
    );
}
