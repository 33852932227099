import React, { useState } from "react";

//packages-----
import { Link } from "react-router-dom";
import styled from "styled-components";

//components
import DettLandingPageHeader from "../../components/includes/header/DettLandingPageHeader";
import GiftFilterPage from "./gift-product-listing/GiftFilterPage";
import GiftListingPage from "../screens/gift-product-listing/GiftListingPage";
import HomeFooterpage from "./home/HomeFooterpage";

export default function GiftProductListing() {
    //for gift sorting dropdown----
    const [sortDropDown, setSortDropDown] = useState(false);

    //dropdown function-----
    const handleDropown = () => {
        setSortDropDown(!sortDropDown);
    };
    return (
        <>
            {
                //dett main header------
                <Dettheader>
                    <DettLandingPageHeader />
                </Dettheader>
            }

            {
                //dett gifts mobile screen header-----
                <GiftProductHeader>
                    <HeaderItemsContainer className="wrapper">
                        <HeaderLeftSide>
                            <BackArrowImageContainer to="/gift">
                                <BackArrowImage
                                    src={
                                        require("../../assets/images/gift-product-listing/backArrowImage.svg")
                                            .default
                                    }
                                />
                            </BackArrowImageContainer>
                            <PageHeading>Watches</PageHeading>
                        </HeaderLeftSide>

                        <HeaderRightSide>
                            <HeaderIconsContainer>
                                <SearchIconContainer>
                                    <SearchIcon
                                        src={
                                            require("../../assets/images/gift-product-listing/headerSearchIcon.svg")
                                                .default
                                        }
                                    />
                                </SearchIconContainer>

                                <CartIconContainer>
                                    <SearchIcon
                                        src={
                                            require("../../assets/images/gift-product-listing/cartIcon.svg")
                                                .default
                                        }
                                    />
                                </CartIconContainer>

                                <GiftIconContainer>
                                    <SearchIcon
                                        src={
                                            require("../../assets/images/gift-product-listing/giftIcon.svg")
                                                .default
                                        }
                                    />
                                </GiftIconContainer>
                            </HeaderIconsContainer>
                        </HeaderRightSide>
                    </HeaderItemsContainer>
                </GiftProductHeader>
            }

            {
                //pink background colour container------
                <ColouredContainer></ColouredContainer>
            }

            {
                //gifts search bar, watch label and sort dropdown-------
                <MainContainer>
                    <SubContainer className="wrapper">
                        <TopContainer>
                            <SearchBarContainer>
                                <InputFieldContainer>
                                    <SerachImageContainer>
                                        <SearchImage
                                            src={
                                                require("../../assets/images/gift-product-listing/SearchIcon.svg")
                                                    .default
                                            }
                                            alt="search-image"
                                        />
                                    </SerachImageContainer>

                                    <SearchForm>
                                        <SerachInput
                                            type="text"
                                            placeholder="Watches"
                                        />
                                    </SearchForm>
                                </InputFieldContainer>

                                <OpenCloserContainer>
                                    <CloseImage
                                        src={
                                            require("../../assets/images/gift-product-listing/closeImage.svg")
                                                .default
                                        }
                                        alt="close-image"
                                    />
                                </OpenCloserContainer>
                            </SearchBarContainer>
                        </TopContainer>

                        <BottomContainer>
                            <WatchDetailsContainer>
                                <GiftOrWatch>
                                    Gift /<ProductType>Watch</ProductType>{" "}
                                </GiftOrWatch>
                                <GiftName>Watches</GiftName>
                                <WatchCount>100 + Results</WatchCount>
                            </WatchDetailsContainer>

                            <WatchFilter
                                onClick={() => {
                                    handleDropown();
                                }}
                            >
                                <SelectionSort>
                                    Sort by : <Selection>Recommended</Selection>
                                </SelectionSort>
                                <ArrowImageContainer>
                                    <ArrowImage
                                        src={
                                            require("../../assets/images/gift-product-listing/arrowImage.svg")
                                                .default
                                        }
                                        alt="Arrow-Image"
                                    />
                                </ArrowImageContainer>
                            </WatchFilter>

                            {sortDropDown && 
                                <SortDropDown>
                                    <SortLabel>SELECT</SortLabel>
                                    <SortLabel>RECOMMENDED</SortLabel>
                                    <SortLabel>LOWEST</SortLabel>
                                    <SortLabel>HIGHEST</SortLabel>
                                </SortDropDown>
                            }
                        </BottomContainer>
                    </SubContainer>
                </MainContainer>
            }

            {
                //left side sort and filter component and gift listing component--------
                <GiftingFilterAndListingComponents>
                    <GiftFilterPage />
                    <GiftListingPage />
                </GiftingFilterAndListingComponents>
            }

            {
                //dett footer section-------
                <FooterContainer>
                    <HomeFooterpage />
                </FooterContainer>
            }
        </>
    );
}

const Dettheader = styled.div`
    @media all and (max-width: 480px) {
        display: none;
    }
`;

const GiftProductHeader = styled.div`
    padding: 25px 0px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
`;

const HeaderItemsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeaderLeftSide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BackArrowImageContainer = styled(Link)`
    width: 25px;
    margin-right: 10px;
`;

const BackArrowImage = styled.img`
    width: 100%;
    display: block;
`;

const PageHeading = styled.h1`
    font-size: 16px;
`;

const HeaderRightSide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeaderIconsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SearchIconContainer = styled.div`
    width: 25px;
    margin-right: 20px;
`;

const SearchIcon = styled.img`
    width: 100%;
    display: block;
`;

const CartIconContainer = styled.div`
    width: 25px;
    margin-right: 20px;
`;

const GiftIconContainer = styled.div`
    width: 25px;
`;

const ColouredContainer = styled.div`
    width: 100%;
    min-height: 80px;
    background: #fff2f1;

    @media all and (max-width: 768px) {
        min-height: 130px;
    }

    @media all and (max-width: 480px) {
        display: none;
    }
`;

const MainContainer = styled.div`
    padding-bottom: 35px;
    position: relative;

    @media all and (max-width: 480px) {
        padding: 30px 0px 50px;
    }
`;

const SubContainer = styled.div`
    @media all and (max-width: 980px) {
        width: 80%;
    }

    @media all and (max-width: 480px) {
        width: 90%;
    }
`;

const TopContainer = styled.div`
    margin-bottom: 80px;
    position: relative;

    @media all and (max-width: 480px) {
        display: none;
    }
`;

const SearchBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    width: 42%;
    padding: 16px 20px;
    border-radius: 30px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 55px;
    background: #fff;

    @media all and (max-width: 980px) {
        padding: 15px 20px;
        width: 50%;
    }
`;

const InputFieldContainer = styled.div`
    display: flex;
`;

const SerachImageContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`;

const SearchImage = styled.img`
    width: 100%;
    display: block;
`;

const SearchForm = styled.div``;

const SerachInput = styled.input`
    display: inline-block;
    color: #000;
    font-size: 14px;
    font-family: "dm_sansboldmedium";
`;

const OpenCloserContainer = styled.div`
    width: 20px;
    cursor: pointer;
`;

const CloseImage = styled.img`
    width: 100%;
    display: block;
`;

const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const WatchDetailsContainer = styled.div``;

const GiftOrWatch = styled.h2`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "dm_sansregular";
    color: #777a86;
    margin-bottom: 5px;

    @media all and (max-width: 768px) {
        margin-bottom: 0px;
    }
`;

const ProductType = styled.span`
    display: inline-block;
    font-size: 16px;
    font-family: "dm_sansregular";
    color: #282c3f;
    margin-left: 4px;
`;

const GiftName = styled.h1`
    font-size: 28px;
    font-family: "dm_sansboldmedium";
    color: #282c3f;
    margin-bottom: 5px;

    @media all and (max-width: 980px) {
        font-size: 24px;
    }

    @media all and (max-width: 768px) {
        margin-bottom: 0px;
    }
`;

const WatchCount = styled.h3`
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const WatchFilter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eeeeee;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
`;

const SelectionSort = styled.h2`
    display: flex;
    align-items: center;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const Selection = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    margin-left: 4px;
    margin-right: 10px;
`;

const ArrowImageContainer = styled.div`
    width: 12px;
`;

const ArrowImage = styled.img`
    width: 100%;
    display: block;
`;

const SortDropDown = styled.div`
    width: 16%;
    border: 1px solid #808080;
    border-radius: 6px;
    position: absolute;
    right: 6%;
    bottom: -40%;
    background: #fff;
    z-index: 1;
`;

const SortLabel = styled.h3`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    cursor: pointer;

    :hover {
        background: #0000ff;
        color: #fff;
    }
`;

const GiftingFilterAndListingComponents = styled.div`
    display: flex;
    width: 75%;
    margin: 0 auto;
    border-top: 1px solid #eeeeee;

    @media all and (max-width: 480px) {
        width: 100%;
    }
`;

const FooterContainer = styled.div`
    @media all and (max-width: 480px) {
        display: none;
    }
`;
