import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const userDataInitialState = {
    phone: "",
    access_token: "",
    is_exists: false,
    logged_in: false,
    phone_code: "",
    flag: "",
    country_code: "",
    phone_number_length: 10,
    id: "",
    user_name: "",
};

const accountDetails = {
    first_name: "",
    last_name: "",
    first_letters_of_name: "",
    gender: "",
    email: "",
    email_verified: false,
};

const is_verified = "pending";

const initialState = {
    user_data: localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : userDataInitialState,

    searchedText: "",

    isVerified: localStorage.getItem("is_verified")
        ? JSON.parse(localStorage.getItem("is_verified"))
        : is_verified,

    account_details: localStorage.getItem("account_details")
        ? JSON.parse(localStorage.getItem("account_details"))
        : accountDetails,
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);

export default Store;
