import React, { useState } from "react";

//packages-------
import styled from "styled-components";

//images---------
import largeImage from "../../../assets/images/gift-trending/largeImg.svg";
import ValentinesDay from "../../../assets/images/gift-trending/ValentineImg.svg";
import Birthday from "../../../assets/images/gift-trending/birthdayImg.svg";
import Anniversary from "../../../assets/images/gift-trending/anniversaryImg.svg";
import gifImg from "../../../assets/images/gift-images/ShopGiftImg.png";
import { Link } from "react-router-dom";

const GiftTrendingImage = () => {
    const Datas = [
        {
            id: 1,
            url: largeImage,
            name: "header",
        },
        {
            id: 2,
            url: ValentinesDay,
            name: "main",
        },
        {
            id: 3,
            url: Birthday,
            name: "main",
        },
        {
            id: 4,
            url: Anniversary,
            name: "main",
        },
    ];
    const [hover, setHover] = useState(1);

    return (
        <Container>
            <SubContainer className="wrapper">
                <Cover>
                    <TrendingGiftLabel>
                        Trending <ColourLabel>Gift's</ColourLabel>{" "}
                        <LightLabel>For you</LightLabel>
                    </TrendingGiftLabel>
                </Cover>

                <Box>
                    {Datas.map((item) => (
                        <ImageContainer
                            className={item.name}
                            onMouseOver={() => setHover(item.id)}
                            onMouseOut={() => setHover("")}
                        >
                            <TrendingImages src={item.url} />
                            {hover == item.id ? (
                                <ShopContainer to="/gift-listing">
                                    <ShopNowLabel>Shop Now</ShopNowLabel>
                                    <GiftImage>
                                        <GiftImg src={gifImg} />
                                    </GiftImage>
                                </ShopContainer>
                            ) : null}
                        </ImageContainer>
                    ))}
                </Box>
            </SubContainer>
        </Container>
    );
};

export default GiftTrendingImage;

const Container = styled.div`
    padding-bottom: 45px;
`;

const SubContainer = styled.div``;

const Cover = styled.div``;

const TrendingGiftLabel = styled.h2`
    font-size: 28px;
    font-family: "dm_sansboldbold";
    margin-bottom: 25px;

    @media all and (max-width: 1150px) {
        margin: 50px 0px 25px;
    }

    @media all and (max-width: 640px) {
        font-size: 24px;
    }

    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`;

const ColourLabel = styled.span`
    font-size: 28px;
    color: #ff5e48;
    font-family: "dm_sansboldbold";

    @media all and (max-width: 640px) {
        font-size: 24px;
    }

    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`;

const LightLabel = styled.span`
    font-size: 28px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 640px) {
        font-size: 24px;
    }

    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`;

const Box = styled.div`
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;

    @media all and (max-width: 480px) {
        gap: 15px;
    }

    div {
        &.header {
            width: 100%;
        }
        &.main {
            width: 31%;

            @media all and (max-width: 480px) {
                width: 100%;
            }
        }
    }
`;

const ImageContainer = styled.div`
    border-radius: 10px;
    overflow: hidden;
    position: relative;
`;

const TrendingImages = styled.img``;

const ShopContainer = styled(Link)`
    position: absolute;
    top: 42%;
    height: 50px;
    width: 100%;
    background: #ffffff60;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    transition: 0.4s ease;
    cursor: pointer;
`;

const ShopNowLabel = styled.h2`
    font-size: 18px;
    color: #ffff;
    font-family: "dm_sansboldmedium";
`;

const GiftImage = styled.div`
    filter: invert(100);
`;

const GiftImg = styled.img``;
