import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Notifications() {
    return (
        <MainContainer className="main-container">
            <TopContainer>
                <TitleBox>
                    <LeftArrowBox to={"/account"}>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/left-arrow.svg"
                            }
                            alt="left-arrow"
                        />
                    </LeftArrowBox>
                    <PageTitle>Notifications</PageTitle>
                </TitleBox>
            </TopContainer>
            <BottomContainer>

                <OrdersList>
                    <OrderCard>
                        <OrderIcon>
                            <img
                                src={require("../../../assets/images/product-single/cancelled.png")}
                                alt="cancelled-icon"
                            />
                        </OrderIcon>
                        <OrderContent>
                            <OrderTitle>Oops ! Order Cancelled</OrderTitle>
                            <OrderDescription>
                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit. Nibh ipsum urna Lorem ipsum dolor sit amet,{" "}
                            </OrderDescription>
                            <Date>08, Dec 2022</Date>
                        </OrderContent>
                    </OrderCard>
                    <OrderCard>
                        <OrderIcon>
                            <img
                                src={require("../../../assets/images/product-single/sucess-order.png")}
                                alt="sucess-icon"
                            />
                        </OrderIcon>
                        <OrderContent>
                            <OrderTitle>Your Order Has Been Delivered Sucessfully!</OrderTitle>
                            <OrderDescription>
                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit. Nibh ipsum urna Lorem ipsum dolor sit amet,{" "}
                            </OrderDescription>
                            <Date>08, Dec 2022</Date>
                        </OrderContent>
                    </OrderCard>
                </OrdersList>
            </BottomContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #f8f8f8;
    width: 100%;
    padding: 20px;
    border-radius: 10px;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        background: #fff;
        padding: 0;
        transition: all 0.5s ease-in-out;
    }

    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
        padding: 20px;
    }
`;
const TitleBox = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    padding: 20px 10px;
    margin-bottom: 20px;
`;

const LeftArrowBox = styled(Link)`
    cursor: pointer;
    width: 25px;
`;
const PageTitle = styled.h2`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-left: 10px;
    color: #0a0a0a;
`;
const BottomContainer = styled.div``;
const OrdersList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const OrderCard = styled.li`
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 20px;
    border-radius: 8px;
`;
const OrderIcon = styled.div`
    width: 35px;
    margin: 7px 20px 0 0;
    img {
        height: auto;
    }
`;
const OrderContent = styled.div`
    width: 90%;
`;
const OrderTitle = styled.h3`
    font-family: "dm_sansboldbold";
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
`;
const OrderDescription = styled.p`
    color: #777A86;
    margin: 10px 0;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const Date = styled.p`
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
