import React, { Suspense, lazy } from "react";

//packages
import { Routes, Route } from "react-router-dom";

//loader
import Loader from "../../general/loader/Loader";

//components
import Career from "../../screens/Career";
import About from "../../screens/About";
import PartnerSinglePage from "../../screens/corporate-gifting/PartnerSinglePage";
import EmptyCart from "../../screens/empty-screens/EmptyCart";
import EmptyOrders from "../../screens/empty-screens/EmptyOrders";
import EmptyNotification from "../../screens/empty-screens/EmptyNotification";
import EmptyAddress from "../../screens/empty-screens/EmptyAddress";
import EmptyPayement from "../../screens/empty-screens/EmptyPayement";

import ProductSortPage from "../../includes/SortAndFilter/ProductSortPage";
import ProductFilterPage from "../../includes/SortAndFilter/ProductFilterPage";

//routes
import AccountRouter from "./AccountRouter";
import PrivateRoute from "../routes/PrivateRoute";
import ProductRouter from "./ProductRouter";
import GiftProductListing from "../../screens/GiftProductListing";
import GiftFilterPage from "../../screens/gift-product-listing/GiftFilterPage";
import GiftListingPage from "../../screens/gift-product-listing/GiftListingPage";
//pdf
import PdfViewer from '../../includes/PdfViewer/PdfViewer'


//components
const DettLandingPage = lazy(() => import("../../screens/DettLandingPage"));
const OccasionSubmitForm = lazy(() =>
  import("../../screens/prebook/OccasionSubmitForm")
);
const LoginPage = lazy(() => import("../../screens/prebook/LoginPage"));
const PrebookLandingPage = lazy(() =>
  import("../../screens/prebook/PrebookLandingPage")
);
const Privacypolicy = lazy(() => import("../../includes/other/Privacypolicy"));
const TermandCondition = lazy(() =>
  import("../../includes/other/TermandCondition")
);
const OtpPage = lazy(() => import("../../screens/prebook/OtpPage"));
const Register = lazy(() => import("../../screens/prebook/Register"));
const CorporateGifting = lazy(() => import("../../screens/CorporateGifting"));
const ResellerRegistrationForm = lazy(() =>
  import("../../includes/other/ResellerRegistrationForm")
);
const ProductSinglepage = lazy(() => import("../../screens/ProductSinglepage"));
const CartPage = lazy(() => import("../../screens/CartPage"));
const WishList = lazy(() => import("../../includes/other/WishList"));

function AppRouter() {
  // const DeviceScreenAddress = lazy(() =>
  //     import("../../includes/AddressHome/DeviceScreenAddress")
  // );
  // const DeviceScreenNewAddress = lazy(() =>
  //     import("../../includes/AddressHome/DeviceScreenNewAddress")
  // );
  // const Address = lazy(() => import("../../screens/Address"));
  // const CardSingle = lazy(() => import("../../includes/greetingpage/CardSingle"));
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<DettLandingPage />} />
        <Route path="/about" element={<About />} />

        <Route path="/prebook" element={<PrebookLandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/otp" element={<OtpPage />} />
        <Route path="/register" element={<Register />} />

        <Route
          path="/pre-book"
          element={
            <PrivateRoute>
              <OccasionSubmitForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/account/*"
          element={
            <PrivateRoute>
              <AccountRouter />
            </PrivateRoute>
          }
        />

        <Route path="/careers" element={<Career />} />

        <Route
          path="/reseller-registration"
          element={<ResellerRegistrationForm />}
        />

        <Route path="/corporategift" element={<CorporateGifting />} />
        <Route
          path="/corporategift/partners/:partner_id"
          element={<PartnerSinglePage />}
        />

        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsandcondition" element={<TermandCondition />} />

        <Route path="empty-cart" element={<EmptyCart />} />

        <Route path="empty-orders" element={<EmptyOrders />} />

        <Route path="empty-notification" element={<EmptyNotification />} />

        <Route path="empty-address" element={<EmptyAddress />} />

        <Route path="empty-payement" element={<EmptyPayement />} />

        {/* <Route
                    path="/singlepage"
                    element={<CardSingle />}
                /> */}

        {/* <Route
                    path="/address"
                    element={<Address />}
                />

                <Route
                    path="/devicescreenaddress"
                    element={<DeviceScreenAddress />}
                />

                <Route
                    path="/devicescreennewaddress"
                    element={<DeviceScreenNewAddress />}
                /> */}

        {/* <Route
                    path="giftListing"
                    element={<GiftProductListing />}
                /> */}
        <Route path="/viewcart" element={<CartPage />} />

        <Route path="/gift/*" element={<ProductRouter />} />

        <Route path="/product-single-page" element={<ProductSinglepage />} />

        <Route path="/gift-listing" element={<GiftProductListing />} />

        <Route path="/gift-filter" element={<GiftFilterPage />} />

        <Route path="/gift-product" element={<GiftListingPage />} />

        {/* <Route path="/gift-filter-mob" element={<ProductFilterPage />} /> */}

        {/* <Route
                    path="/wishlist"
                    element={<WishList />} 
                />

                {/* <Route
                    path="/errorpage"
                    element={<GiftErrorPage />}
                /> */}
        <Route path="/annual-reports" element={<PdfViewer />} />
      </Routes>
    </Suspense>
  );
}

export default AppRouter;
