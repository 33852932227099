import React, { useState } from "react";
import styled from "styled-components";

//packages----
import { Outlet } from "react-router-dom";

//components----
import DettLandingPageHeader from "../../includes/header/DettLandingPageHeader";

//Images----
import BgImg from "../../../assets/images/gift-spotlight/GifSpotlightBgImg.svg";

export default function GiftSpotlight() {

    //spotlight page main events-----
    const main_events = [
        {
            id: 1,
            event_name: "Baby Shower",
        },

        {
            id: 2,
            event_name: "Birthday",
        },

        {
            id: 3,
            event_name: "Wedding Anniversary",
        },

        {
            id: 4,
            event_name: "Baptism",
        },

        {
            id: 5,
            event_name: "Anniversary",
        },

        {
            id: 6,
            event_name: "Wedding",
        },
    ];

    const [activeLabel, setActiveLabel] = useState("Baby Shower");

    return (
        <>
            <DettLandingPageHeader />
            <MainContainer>
                <SubContainer className="wrapper">
                    <Container>
                        <TopContainer>
                            <EventsContainer>
                                <EventListing>
                                    {main_events.map((item, index ) => (
                                        <Events key={index}>
                                            <EventsLabel
                                                className={
                                                    activeLabel == item.id
                                                        ? "active"
                                                        : null
                                                }
                                                onClick={() => {
                                                    setActiveLabel(item.id);
                                                }}
                                            >
                                                {item.event_name}
                                            </EventsLabel>
                                        </Events>
                                    ))}
                                </EventListing>
                            </EventsContainer>
                        </TopContainer>

                        <BottomContainer>
                            <QuoteLabel>
                                Re-Define Your Gifting Experience
                            </QuoteLabel>
                            <QuoteParagraph>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vivamus hendrerit elementum
                                urna, vel venenatis nisi pretium et. Curabitur
                                sit amet porttitor ante.
                            </QuoteParagraph>
                            <SearchContainer>
                                <SearchImageContainer>
                                    <SearchImage
                                        src={
                                            require("../../../assets/images/gift-spotlight/searchIcon.png")
                                        }
                                        alt="Search Icon"
                                    />
                                </SearchImageContainer>
                                <SearchInput placeholder="Search Anniversary, Birthday, Wedding..." />
                                <SearchButton>Search</SearchButton>
                            </SearchContainer>
                        </BottomContainer>
                    </Container>
                </SubContainer>
            </MainContainer>
            <Outlet />
        </>
    );
}

const MainContainer = styled.div`
    padding: 80px 0px;
    background: url(${BgImg});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media all and (max-width: 1280px) {
        padding: 40px 0px;
    }

    @media all and (max-width: 1150px) {
        background-size: cover;
    }

    @media all and (max-width: 1080px) {
        
    }

    @media all and (max-width: 980px) {
        
    }

    @media all and (max-width: 768px) {
        
    }

    @media all and (max-width: 640px) {
        
    }

    @media all and (max-width: 480px) {
        
    }

    @media all and (max-width: 360px) {
        
    }
`;

const SubContainer = styled.div``;

const Container = styled.div``;

const TopContainer = styled.div`
    margin: 100px auto 50px;

    @media all and (max-width: 640px) {
        margin: 60px auto 40px;
    }
`;

const EventsContainer = styled.div``;

const EventListing = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: 1080px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    @media all and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const Events = styled.li``;

const EventsLabel = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    color: #fff;
    background: #0a0a0a;
    padding: 15px 35px;
    border-radius: 40px;
    cursor: pointer;
    &.active {
        background: #fff;
        color: #000;
    }

    :hover {
        background: #fff;
        color: #000;
    }

    @media all and (max-width:1280px) {
        padding: 15px 25px;
    }

    @media all and (max-width:1150px) {
        padding: 15px 20px;
    }

    @media all and (max-width: 1080px) {
        text-align: center;
    }

    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    
`;

const BottomContainer = styled.div``;

const QuoteLabel = styled.h1`
    width: 45%;
    margin: 0 auto 30px;
    font-size: 48px;
    color: #fff;
    font-family: "dm_sansboldbold";
    text-align: center;

    @media all and (max-width: 1150px) {
        width: 60%;
    }

    @media all and (max-width: 980px) {
        width: 70%;
    }

    @media all and  (max-width: 916px) {
        width: 65%;
    }

    @media all and (max-width: 768px) {
        width: 80%;
    }

    @media all and (max-width: 640px) {
        font-size: 36px;
    }

    @media all and (max-width: 480px) {
        width: 100%;
        font-size: 32px;
        margin-bottom: 20px;
    }

    @media all and (max-width: 320px) {
        font-size: 28px;
        margin: 0 auto 20px;
    }
`;

const QuoteParagraph = styled.p`
    width: 40%;
    margin: 0 auto 30px;
    font-size: 16px;
    color: #fff;
    font-family: "dm_sansboldmedium";
    text-align: center;

    @media all and (max-width: 1150px) {
        width: 60%;
    }

    @media all and (max-width: 980px) {
        width: 75%;
    }

    @media all and (max-width: 768px) {
        width: 80%;
    }

    @media all and (max-width: 480px) {
        width: 100%;
        font-size: 14px;
    }

    @media all and (max-width: 320px) {
    }
`;

const SearchContainer = styled.div`
    width: 45%;
    margin: 0 auto 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 2px;
    border-radius: 30px;

    @media all and (max-width: 1150px) {
        width: 60%;
        margin: 0 auto 20px;
    }

    @media all and (max-width: 980px) {
        width: 75%;
    }

    @media all and (max-width: 768px) {
        width: 80%;
    }

    @media all and (max-width: 480px) {
        width: 100%;
    }
`;

const SearchImageContainer = styled.div`
    width: 20px;
    margin-left: 10px;
    margin-right: 10px;

    @media all and (max-width: 480px) {
        margin-right: 5px;
    }
`;

const SearchImage = styled.img`
    width: 100%;
    display: block;
`;

const SearchInput = styled.input`
    font-size: 14px;
    font-family: "dm_sansregular";
    width: 80%;

    @media all and (max-width: 640px) {
        font-size: 12px;
    }

    
`;

const SearchButton = styled.div`
    color: #fff;
    font-size: 16px;
    font-family: "dm_sansregular";
    background: #ff5e48;
    padding: 12px 30px;
    border-radius: 25px;
    cursor: pointer;

    @media all and (max-width: 640px) {
        padding: 12px 20px;
    }

    @media all and (max-width: 480px) {
        padding: 10px 15px;
        font-size: 14px;
    }
`;