import React from "react";

//packages
import styled from "styled-components";
import { Routes, Route, useLocation } from "react-router-dom";

//components
import Profile from "../../screens/account/Profile";
import SideBar from "../../screens/account/SideBar";
import Header from "../../includes/header/DettLandingPageHeader";
import HomeFooterpage from "../../screens/home/HomeFooterpage";
import Notifications from "../../screens/account/Notifications";
import SavedAddress from "../../screens/account/SavedAddress";
import PaymentOptions from "../../screens/account/PaymentOptions";
import ContactUs from "../../screens/account/ContactUs";

function AccountRouter() {
    //current route
    const location = useLocation();

    return (
        <>
            <Header />
            <MainConntainer>
                <Wrapper>
                    <LefBox
                        className="left-box"
                        location={location.pathname}
                    >
                        <SideBar />
                    </LefBox>
                    <RightBox>
                        <Routes>
                            <Route
                                path="/"
                                element={<SideBar />}
                            />
                            <Route
                                path="/user-profile"
                                element={<Profile />}
                            />
                            <Route
                                path="/notifications"
                                element={<Notifications />}
                            />
                            <Route
                                path="/saved-adress"
                                element={<SavedAddress />}
                            />
                            <Route
                                path="/payment-options"
                                element={<PaymentOptions />}
                            />
                            <Route
                                path="/contact-us"
                                element={<ContactUs />}
                            />
                        </Routes>
                    </RightBox>
                </Wrapper>
            </MainConntainer>
            <Border></Border>
            <BottomBox>
                <HomeFooterpage />
            </BottomBox>
        </>
    );
}

export default AccountRouter;

const MainConntainer = styled.section`
    width: 100%;
    background-color: #fff;
    margin-top: 120px;
    padding-bottom: 70px;
    @media all and (max-width: 768px) {
        padding-bottom: 0px;
        margin-top: 65px;
    }
`;

const Wrapper = styled.section`
    display: flex;
    width: 75%;
    margin: 0 auto;

    @media all and (max-width: 768px) {
        width: 100%;
    }
`;

const LefBox = styled.div`
    width: 35%;
    //hiding sidebar in mobilescreen(below 768px), ie, "/account" route will be activated in  mobile screens(below 768)
    display: ${({ location }) => (location === "/account" ? "none" : "block")};
    @media all and (max-width: 768px) {
        display: ${({ location }) => ((location === "/account/user-profile" || location === "/account/notifications" || location === "/account/saved-adress" || location === "/account/payment-options" || location === "/account/contact-us") ? "none" : "")};
    }
`;
const RightBox = styled.div`
    width: 70%;
    @media all and (max-width: 768px) {
        width: 85%;
        margin: 0 auto;
        margin-top: 10px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;

const Border = styled.hr`
    width: 100%;
    height: 0.5px;
    background-color: #eeeeee;
`;

const BottomBox = styled.div`
    width: 100%;
    @media all and (max-width: 768px) {
        display: none;
    }
`;
