import React, { useEffect, useState } from "react";

//packages
import styled from "styled-components";
import { useParams } from "react-router-dom";

//components
import PartnerTestimonials from "./partners-single-page/PartnerTestimonials";
import Banner from "./partners-single-page/Banner";
import Occassions from "./partners-single-page/Occassions";
import PartnerLogo from "./partners-single-page/PartnerLogo";
import HomeFooterpage from "../home/HomeFooterpage";

//axios
import { dettConfig } from "../../../axiosConfig";

function PartnerSinglePage() {
    //states
    const [data, setData] = useState([]);

    //id of selected partner(clicked partner)
    const { partner_id } = useParams();

    //scroll to top of single-page
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    //fetching partner data
    useEffect(() => {
        dettConfig
            .get(`api/v1/bookings/corporate-gifting/connected-partners/${partner_id}/`)
            .then((response) => {
                const { data } = response?.data;
                setData(data);
            })
            .catch((error) => {});
    }, [partner_id]);

    return (
        <>
            <MainContainer>
                <PartnerLogo data={data} />
                <Occassions occasions={data?.occasions} />
                <PartnerTestimonials testimonials={data?.testimonials} />
                <Banner />
                <HomeFooterpage />
            </MainContainer>
        </>
    );
}

const MainContainer = styled.section``;

export default PartnerSinglePage;
