import React, { useContext, useEffect } from "react";

//packages
import { Routes, Route, useLocation } from "react-router-dom";

//components
import AppRouter from "./AppRouter";

//contest
import { Context } from "../../Contexts/Store";
import ProductRouter from "./ProductRouter";

export default function MainRouter() {
    const {
        state: { user_data },
    } = useContext(Context);

    const location = useLocation();

    // changing sender email to name
    useEffect(() => {
        document.tidioIdentify = {
            distinct_id: user_data?.id, // Unique visitor ID in your system
            email: user_data?.email, // visitor email
            name: user_data?.user_name, // Visitor name
            phone: user_data?.phone, //Visitor phone
        };
        if (window.tidioChatApi) {
            window.tidioChatApi.setVisitorData({
                distinct_id: user_data?.id, // Unique visitor ID in your system
                email: user_data?.email, // visitor email
                name: user_data?.user_name, // Visitor name
                phone: user_data?.phone, //Visitor phone
            });
        }
    }, [user_data, window.tidioChatApi]);

    //hiding tidio in corporate-gifting page
    useEffect(() => {
        const tidioScript = document.getElementById("tidio-chat");
        if (tidioScript) {
            const currentPath = location.pathname;
            const excludedPath = "/corporategift";
            const isExcluded = currentPath.startsWith(excludedPath);
            const shouldShow = !isExcluded || currentPath.startsWith(`${excludedPath}/`);
            tidioScript.style.display = shouldShow ? "block" : "none";
        }
    }, [location, location.pathname]);

    return (
        <Routes>
            <Route path="/*" element={<AppRouter />} />
        </Routes>
    );
}
