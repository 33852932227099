import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function SavedAddress() {
    return (
        <MainContainer className="main-container">
            <TopContainer>
                <TitleBox>
                    <LeftArrowBox to={"/account"}>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/left-arrow.svg"
                            }
                            alt="left-arrow"
                        />
                    </LeftArrowBox>
                    <PageTitle>Saved Address</PageTitle>
                </TitleBox>
            </TopContainer>
            <BottomContainer>
                <TitleContainer>
                    <TitleLeft>
                        <Title>Saved Addresses</Title>
                        <DefaultAddress>DEFAULT ADDRESS</DefaultAddress>
                    </TitleLeft>
                    <AddNewAddress>+&nbsp;Add&nbsp;New&nbsp;Address</AddNewAddress>
                </TitleContainer>
                <AddressCard>
                    <AddressIcon>
                        <img
                            src={
                                require("../../../assets/images/product-single/selected-radio-btn.svg")
                                    .default
                            }
                            alt="radio-button"
                        />
                    </AddressIcon>
                    <AddressContent>
                        <AddressTitle>Muhammad Najah TP </AddressTitle>
                        <CustomerAddress>
                            Talrop Techies Park, Kanhirode, Kannur, Kozhikkode
                            Paleri (Po), Kannur, Kozhikkode <br />
                            Pin:673508
                        </CustomerAddress>
                        <PhoneNumber>Mobile : 9539730011</PhoneNumber>
                        <AddressButtons>
                            <FunctionButton>Remove</FunctionButton>
                            <FunctionButton>Edit</FunctionButton>
                        </AddressButtons>
                    </AddressContent>
                </AddressCard>
                <OtherAddress>OTHER ADDRESS</OtherAddress>
                <OtherAddressList>
                    <AddressCard>
                        <AddressIcon>
                            <img
                                src={
                                    require("../../../assets/images/product-single/radio-btn.svg")
                                        .default
                                }
                                alt="radio-button"
                            />
                        </AddressIcon>
                        <AddressContent>
                            <AddressTitle>Muhammad Najah TP </AddressTitle>
                            <CustomerAddress>
                                Talrop Techies Park, Kanhirode, Kannur,
                                Kozhikkode Paleri (Po), Kannur, Kozhikkode{" "}
                                <br />
                                Pin:673508
                            </CustomerAddress>
                            <PhoneNumber>Mobile : 9539730011</PhoneNumber>
                        </AddressContent>
                    </AddressCard>
                    <AddressCard>
                        <AddressIcon>
                            <img
                                src={
                                    require("../../../assets/images/product-single/radio-btn.svg")
                                        .default
                                }
                                alt="radio-button"
                            />
                        </AddressIcon>
                        <AddressContent>
                            <AddressTitle>Muhammad Najah TP </AddressTitle>
                            <CustomerAddress>
                                Talrop Techies Park, Kanhirode, Kannur,
                                Kozhikkode Paleri (Po), Kannur, Kozhikkode{" "}
                                <br />
                                Pin:673508
                            </CustomerAddress>
                            <PhoneNumber>Mobile : 9539730011</PhoneNumber>
                        </AddressContent>
                    </AddressCard>
                    <AddressCard>
                        <AddressIcon>
                            <img
                                src={
                                    require("../../../assets/images/product-single/radio-btn.svg")
                                        .default
                                }
                                alt="radio-button"
                            />
                        </AddressIcon>
                        <AddressContent>
                            <AddressTitle>Muhammad Najah TP </AddressTitle>
                            <CustomerAddress>
                                Talrop Techies Park, Kanhirode, Kannur,
                                Kozhikkode Paleri (Po), Kannur, Kozhikkode{" "}
                                <br />
                                Pin:673508
                            </CustomerAddress>
                            <PhoneNumber>Mobile : 9539730011</PhoneNumber>
                        </AddressContent>
                    </AddressCard>
                </OtherAddressList>
            </BottomContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #f8f8f8;
    width: 100%;
    padding: 20px;
    border-radius: 10px;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        background: #fff;
        padding: 0;
        transition: all 0.5s ease-in-out;
    }

    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
        padding: 20px;
    }
`;
const TitleBox = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    padding: 20px 10px;
    margin-bottom: 20px;
`;

const LeftArrowBox = styled(Link)`
    cursor: pointer;
    width: 25px;
`;
const PageTitle = styled.h2`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-left: 10px;
    color: #0a0a0a;
`;
const BottomContainer = styled.div`
    @media all and (max-width: 480px) {
        width: 85%;
        margin: 0 auto;
        margin-top: 10px;
    }
`;
const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const TitleLeft = styled.div``;
const Title = styled.h3`
    font-family: "dm_sansboldbold";
    margin-bottom: 15px;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
`;
const DefaultAddress = styled.h4`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;
const AddNewAddress = styled.div`
    font-family: "dm_sansboldmedium";
    border: 1px solid #ff7d6c;
    color: #ff5740;
    padding: 10px 30px;
    border-radius: 8px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 980px) {
        padding: 10px;
    }
    @media all and (max-width: 360px) {
        padding: 10px 5px;
    }
`;
const AddressCard = styled.div`
    background: #fff;
    display: flex;
    align-items: start;
    padding: 20px;
    border-radius: 8px;
    gap: 20px;
    @media all and (max-width: 480px) {
        gap: 10px;
    }
`;
const AddressIcon = styled.div`
    margin-top: 5px;
    @media all and (max-width: 480px) {
        width: 13px;
    }
`;
const AddressContent = styled.div`
    @media all and (max-width: 480px) {
        width: 80%;
    }
`;
const AddressTitle = styled.h6`
    font-family: "dm_sansboldmedium";
    font-size: 18px;
    @media all and (max-width: 1280px) {
        font-size: 16px;
    }
`;
const CustomerAddress = styled.p`
    width: 69%;
    margin: 10px 0;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const PhoneNumber = styled.p`
    font-family: "dm_sansboldmedium";
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const AddressButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
`;
const FunctionButton = styled.div`
    font-family: "dm_sansboldmedium";
    border: 1px solid #ff7d6c;
    color: #ff5740;
    padding: 10px 30px;
    border-radius: 8px;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const OtherAddress = styled.h4`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin: 30px 0;
`;
const OtherAddressList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
