import React, { useEffect, useState } from "react";

//packages
import styled from "styled-components";
import useOutsideScroll from "../../general/hooks/useOutsideScroll";

function SizeChartModal({ viewSizeChart, setViewSizeChart }) {
    //states
    const [selectedSize, setSelectedSize] = useState(null);
    const [measurementType, setMeasurementType] = useState("IN");
    const [selectedTab, setSelectedTab] = useState("SIZE_GUIDE");

    //headings of sizechart table
    const [titles] = useState(["Size", "Brand Size", "Shoulder", "Chest", "Waist"]);
    const [sizeChart] = useState([
        { id: 1, values: [38, 40, 42, 44, 46] },
        { id: 2, values: ["S", "M", "L", "XL", "XXL"] },
        { id: 3, values: [39.5, 42.5, 45.3, 48.3, 52.3] },
        { id: 4, values: [29.0, 29.4, 29.4, 30.1, 32.1] },
        { id: 5, values: [17, 18, 19, 20, 21] },
    ]);

    //preventing outside scroll
    useOutsideScroll(viewSizeChart);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [viewSizeChart]);

    return (
        <MainContainer>
            <Overlay
                onClick={() => setViewSizeChart(false)}
                className={viewSizeChart && "view"}
            ></Overlay>
            <SizeChart className={viewSizeChart && "animate"}>
                <TopBox>
                    <Heading>Brand : G-Shock</Heading>
                    <CloseIcon onClick={() => setViewSizeChart(false)}>
                        <img
                            src={require("../../../assets/images/close-icon-grey.svg").default}
                            alt="close-grey"
                        />
                    </CloseIcon>
                </TopBox>
                <Border></Border>
                <MiddleBox>
                    <Title
                        onClick={() => setSelectedTab("SIZE_GUIDE")}
                        className={selectedTab === "SIZE_GUIDE" && "active"}
                    >
                        Size Guide
                    </Title>
                    <Title
                        onClick={() => setSelectedTab("HOW_TO_MEASURE")}
                        className={selectedTab === "HOW_TO_MEASURE" && "active"}
                    >
                        How To Measure
                    </Title>
                </MiddleBox>
                <Border className="thick">
                    <RedBorder className={selectedTab === "HOW_TO_MEASURE" && "right"}></RedBorder>
                </Border>
                {selectedTab === "SIZE_GUIDE" ? (
                    <>
                        <MeasurementBox>
                            <Measurement>
                                Measurement <span>{"(inches)"}</span>
                            </Measurement>
                            <MeasurementType measurementType={measurementType}>
                                <Inches
                                    className={measurementType === "IN" && "active"}
                                    onClick={() => setMeasurementType("IN")}
                                >
                                    in
                                </Inches>
                                <Centemeter
                                    className={measurementType === "CM" && "active"}
                                    onClick={() => setMeasurementType("CM")}
                                >
                                    cm
                                </Centemeter>
                            </MeasurementType>
                        </MeasurementBox>
                        <TableContainer>
                            <SizeChartTable>
                                <TableHeadingBox>
                                    {titles?.map((title, index) => {
                                        const words = title.split(" ");
                                        if (words.length === 2) {
                                            return (
                                                <TableHeading key={index}>
                                                    <span>{words[0]}</span>
                                                    <span>{words[1]}</span>
                                                </TableHeading>
                                            );
                                        } else {
                                            return <TableHeading key={index}>{title}</TableHeading>;
                                        }
                                    })}
                                </TableHeadingBox>
                                <Border className="table-border"></Border>
                                <TableDataContainer>
                                    {sizeChart.map(({ id, values }) => (
                                        <TableData key={id}>
                                            {values.map((value, index) => (
                                                <Size
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedSize(value);
                                                        setViewSizeChart(false);
                                                    }}
                                                >
                                                    {value}
                                                </Size>
                                            ))}
                                        </TableData>
                                    ))}
                                </TableDataContainer>
                            </SizeChartTable>
                        </TableContainer>
                    </>
                ) : (
                    <SizeChartImageContainer>
                        <ImageBox>
                            <img
                                src={require("../../../assets/images/shirt-size.png")}
                                alt="size-img"
                            />
                        </ImageBox>
                    </SizeChartImageContainer>
                )}
            </SizeChart>
        </MainContainer>
    );
}

export default SizeChartModal;

const MainContainer = styled.section``;

//OVERLAY STYLES
const Overlay = styled.section`
    width: 100%;
    height: 100vh;
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: none;
    &.view {
        display: block;
        @media all and (max-width: 640px) {
            display: none;
        }
    }
`;

//COMMON BORDER STYLES
const Border = styled.div`
    width: 100%;
    background-color: #d4d4d4;
    height: 1px;
    position: relative;
    &.table-border {
        height: 2px;
    }
    &.thick {
        height: 2.5px;
    }
`;

const SizeChart = styled.section`
    width: 37%;
    background-color: #fff;
    position: absolute;
    z-index: 101;
    top: 0;
    height: 100vh;
    right: -800px;
    transition: all 0.4s ease-in-out;
    @media all and (max-width: 640px) {
        right: -650px;
    }
    &.animate {
        right: 0;
        transition: all 0.4s ease-in-out;
    }
    @media all and (max-width: 980px) {
        width: 50%;
    }

    @media all and (max-width: 768px) {
        width: 65%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;

//TOP SECTION STYLES
const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 25px;
`;
const Heading = styled.h1`
    color: #282c3f;
    font-family: "dm_sansboldmedium";
    font-size: 18px;
`;
const CloseIcon = styled.span`
    display: inline-block;
    background: #eeeeee;
    backdrop-filter: blur(57px);
    border-radius: 50%;
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
`;

//MIDDLE BOX STYLES
const MiddleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
    margin: 0 auto;
    cursor: pointer;
`;

const Title = styled.h2`
    color: #282c3f;
    font-family: "dm_sansregular";
    font-size: 17px;
    width: max-content;
    transition: all 0.4s ease-in-out;
    text-align: center;
    padding: 30px 0 20px;
    &.active {
        transition: all 0.4s ease-in-out;
        font-family: "dm_sansboldmedium";
    }
`;

const RedBorder = styled.span`
    display: inline-block;
    height: 2.5px;
    background-color: red;
    position: absolute;
    width: 50%;
    transition: all 0.4s ease-in-out;
    &.right {
        transition: all 0.4s ease-in-out;
        transform: translateX(100%);
    }
`;

//MEASUREMENT SECTION STYLES
const MeasurementBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
`;
const Measurement = styled.h3`
    color: #282c3f;
    font-family: "dm_sansboldmedium";
    font-size: 17px;
    span {
        color: #d4d4d4;
        font-size: 17px;
        font-family: "dm_sansboldmedium";
    }
`;
const MeasurementType = styled.div`
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 3px;
`;
const Inches = styled.span`
    font-family: "dm_sansboldmedium";
    cursor: pointer;
    font-size: 17px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #d4d4d4;
    display: inline-block;
    width: 50px;
    text-align: center;
    transition: all 0.4s ease-in-out;
    &.active {
        transition: all 0.4s ease-in-out;
        background: #ff5e48;
        color: #fff;
    }
`;
const Centemeter = styled.span`
    font-family: "dm_sansboldmedium";
    cursor: pointer;
    font-size: 17px;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    width: 50px;
    color: #d4d4d4;
    text-align: center;
    transition: all 0.4s ease-in-out;
    &.active {
        transition: all 0.4s ease-in-out;
        background-color: #ff5e48;
        color: #fff;
    }
`;

//TABLE STYLES[SIZE CHART TABLE]
const TableContainer = styled.div`
    width: 90%;
    margin: 0 auto;
`;

const TableDataContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 25px 0;
    @media all and (max-width: 360px) {
        width: 95%;
    }
`;

const SizeChartTable = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 2px solid #d4d4d4;
    border-radius: 8px;
`;

const TableHeadingBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
    @media all and (max-width: 360px) {
        width: 95%;
    }
`;

const TableHeading = styled.h2`
    width: 20%;
    font-family: "dm_sansboldmedium";
    color: #747474;
    font-size: 15px;
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;

const TableData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
`;

const Size = styled.span`
    color: #000000;
    display: inline-block;
    font-family: "dm_sansboldmedium";
    font-size: 15px;
    margin-bottom: 25px;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;

//IMAGE SECTION[SIZE IMAGE]
const SizeChartImageContainer = styled.div`
    padding-top: 45px;
`;
const ImageBox = styled.div`
    width: 60%;
    margin: 0 auto;
`;
