import React, { useState } from "react";

//packages--------
import styled from "styled-components";
import { Link } from "react-router-dom";

//images--------
import tick from "../../../assets/images/gift-product-listing/tick.png";
import untick from "../../../assets/images/gift-product-listing/untick.png";

export default function GiftFilterPage() {
    const occasions = [
        {
            id: 1,
            occasion: "Birthday",
            count: "(2000)",
        },

        {
            id: 2,
            occasion: "Wedding Anniversary",
            count: "(1000)",
        },

        {
            id: 3,
            occasion: "Anniversay",
            count: "(2500)",
        },

        {
            id: 4,
            occasion: "Baptism",
            count: "(3200)",
        },
    ];

    const Brands = [
        {
            id: 5,
            brand_name: "Vanhusean",
            count: "(2000)",
        },

        {
            id: 6,
            brand_name: "Calvin Klein",
            count: "(1000)",
        },

        {
            id: 7,
            brand_name: "Fossil",
            count: "(2500)",
        },

        {
            id: 8,
            brand_name: "Huami",
            count: "(3000)",
        },
    ];

    const product_prize = [
        {
            id: 9,
            prize_details: "Less than 500",
            prize_count: "(2000)",
        },

        {
            id: 10,
            prize_details: "Rs. 500 to 1000",
            prize_count: "(2000)",
        },

        {
            id: 11,
            prize_details: "Rs. 1000 to 1500",
            prize_count: "(2000)",
        },

        {
            id: 12,
            prize_details: "Rs. 1500 to 2000",
            prize_count: "(2000)",
        },

        {
            id: 13,
            prize_details: "2000 and More",
            prize_count: "(2000)",
        },
    ];

    const proudct_color = [
        {
            id: 14,
            color_name: "Red",
            color_count: "(2000)",
        },

        {
            id: 15,
            color_name: "Blue",
            color_count: "(2000)",
        },

        {
            id: 16,
            color_name: "Black",
            color_count: "(2000)",
        },

        {
            id: 17,
            color_name: "Green",
            color_count: "(2000)",
        },

        {
            id: 18,
            color_name: "White",
            color_count: "(2000)",
        },
    ];

    const discount = [
        {
            id: 19,
            discound_range: "10% and Above",
        },

        {
            id: 20,
            discound_range: "20% and Above",
        },

        {
            id: 21,
            discound_range: "30% and Above",
        },

        {
            id: 22,
            discound_range: "40% and Above",
        },

        {
            id: 23,
            discound_range: "50% and Above",
        },

        {
            id: 24,
            discound_range: "60% and Above",
        },

        {
            id: 25,
            discound_range: "70% and Above",
        },

        {
            id: 26,
            discound_range: "80% and Above",
        },
    ];

    //for selecting filters-----
    const [isTicked, setIsTicked] = useState("");

    const toggleTick = ( item ) => {
        if ( item.id === isTicked ) {
            setIsTicked("");
        } else {
            setIsTicked(item.id);
        }
        console.log(item, "=====ajmal=====");
    };

    //to show search field for searching brands, occacions-----
    const [showSearchField, setShowSearchField] = useState("");

    const handleSearchField = (filter) => {
        if (showSearchField === "" && filter) {
            setShowSearchField(filter);
        } else {
            setShowSearchField("");
        }
    };

    return (
        <MainContainer>
            <SubContainer>
                <LeftSideContainer>
                    <FilterContainer>
                        <FilterHeading>Filter</FilterHeading>
                        <ClearHeading>Clear All</ClearHeading>
                    </FilterContainer>

                    {/* <BrandLabelAndImage>
                        <BrandLabel>Occasions</BrandLabel>
                        <BrandSearchContainer
                            onClick={() => {
                                handleSearchField();
                            }}
                        >
                            <BrandSerachImage
                                src={
                                    require("../../../assets/images/gift-product-listing/searchImage.svg")
                                        .default
                                }
                            />
                        </BrandSearchContainer>
                    </BrandLabelAndImage> */}

                    {showSearchField === "occasions" ? (
                        <FilterSearchContainer>
                            <FilterSearchField
                                type="text"
                                placeholder="Search For Occasions"
                            />
                            <SearchCloseImage onClick={handleSearchField}>
                                <SerachClose
                                    src={
                                        require("../../../assets/images/gift-product-listing/closeImage.svg")
                                            .default
                                    }
                                    alt="close-image"
                                />
                            </SearchCloseImage>
                        </FilterSearchContainer>
                    ) : (
                        <BrandLabelAndImage>
                            <BrandLabel>Occasions</BrandLabel>
                            <BrandSearchContainer
                                onClick={() => {
                                    handleSearchField("occasions");
                                }}
                            >
                                <BrandSerachImage
                                    src={
                                        require("../../../assets/images/gift-product-listing/searchImage.svg")
                                            .default
                                    }
                                />
                            </BrandSearchContainer>
                        </BrandLabelAndImage>
                    )}

                    <OccasionItems>
                        {occasions.map((item, index, id) => (
                            <OccasionsList key={id}>
                                <TickBoxContainer
                                    onClick={() => {
                                        toggleTick(item);
                                    }}
                                >
                                    {item.id === isTicked ? (
                                        <TickBoxImage
                                            src={tick}
                                            alt="check box"
                                        />
                                    ) : (
                                        <TickBoxImage
                                            src={untick}
                                            alt="check box"
                                        />
                                    )}
                                </TickBoxContainer>
                                <OccasionLabel>{item.occasion}</OccasionLabel>
                                <ProductsCountLabel>
                                    {item.count}
                                </ProductsCountLabel>
                            </OccasionsList>
                        ))}
                        <ProductFilterSuggestion>
                            + 12 More
                        </ProductFilterSuggestion>
                    </OccasionItems>

                    <BrandContainer>
                        {showSearchField === "brand" ? (
                            <FilterSearchContainer>
                                <FilterSearchField
                                    type="text"
                                    placeholder="Search For Brands"
                                />
                                <SearchCloseImage
                                    onClick={() => {
                                        handleSearchField();
                                    }}
                                >
                                    <SerachClose
                                        src={
                                            require("../../../assets/images/gift-product-listing/closeImage.svg")
                                                .default
                                        }
                                        alt="close-image"
                                    />
                                </SearchCloseImage>
                            </FilterSearchContainer>
                        ) : (
                            <BrandLabelAndImage>
                                <BrandLabel>Brand</BrandLabel>
                                <BrandSearchContainer
                                    onClick={() => {
                                        handleSearchField("brand");
                                    }}
                                >
                                    <BrandSerachImage
                                        src={
                                            require("../../../assets/images/gift-product-listing/searchImage.svg")
                                                .default
                                        }
                                    />
                                </BrandSearchContainer>
                            </BrandLabelAndImage>
                        )}

                        <ProductBrandConntainer>
                            {Brands.map((item, index) => (
                                <MajorBrands key={index}>
                                    <TickBoxContainer
                                        onClick={() => {
                                            toggleTick(item.id);
                                        }}
                                    >
                                        {isTicked === item.id ? (
                                            <TickBoxImage
                                                src={tick}
                                                alt="check box"
                                            />
                                        ) : (
                                            <TickBoxImage
                                                src={untick}
                                                alt="check box"
                                            />
                                        )}
                                    </TickBoxContainer>

                                    <BrandNameLabel>
                                        {item.brand_name}
                                    </BrandNameLabel>

                                    <BrandItemsCount>
                                        {item.count}
                                    </BrandItemsCount>
                                </MajorBrands>
                            ))}
                            <ProductFilterSuggestion>
                                + 12 More
                            </ProductFilterSuggestion>
                        </ProductBrandConntainer>
                    </BrandContainer>

                    <PrizeContainer>
                        <ProductPrizeLabel>Prize</ProductPrizeLabel>
                        <ProductPrizeFilterContainer>
                            {product_prize.map((item, index) => (
                                <ProductFilterPrize key={index}>
                                    <TickBoxContainer
                                        onClick={() => {
                                            toggleTick(item.id);
                                        }}
                                    >
                                        {isTicked === item.id ? (
                                            <TickBoxImage
                                                src={tick}
                                                alt="check box"
                                            />
                                        ) : (
                                            <TickBoxImage
                                                src={untick}
                                                alt="check box"
                                            />
                                        )}
                                    </TickBoxContainer>

                                    <PrizeLabel>
                                        {item.prize_details}
                                    </PrizeLabel>
                                    <PrizeCount>{item.prize_count}</PrizeCount>
                                </ProductFilterPrize>
                            ))}
                        </ProductPrizeFilterContainer>
                        <MorePrizeLabel>+ 14 More</MorePrizeLabel>
                    </PrizeContainer>

                    <ProductColourContainer>
                        <ColourLabel>Colour</ColourLabel>
                        <ColourFilterContainer>
                            {proudct_color.map((item, index) => (
                                <ProductColours key={index}>
                                    <TickBoxContainer
                                        onClick={() => {
                                            toggleTick(item.id);
                                        }}
                                    >
                                        {isTicked === item.id ? (
                                            <TickBoxImage
                                                src={tick}
                                                alt="check box"
                                            />
                                        ) : (
                                            <TickBoxImage
                                                src={untick}
                                                alt="check box"
                                            />
                                        )}
                                    </TickBoxContainer>

                                    <BulletColour
                                        type={proudct_color[index].color_name}
                                    ></BulletColour>
                                    <ProductColour>
                                        {item.color_name}
                                    </ProductColour>
                                    <ColourCount>
                                        {item.color_count}
                                    </ColourCount>
                                </ProductColours>
                            ))}
                        </ColourFilterContainer>
                        <AdditionalColourCount>+ 14 More</AdditionalColourCount>
                    </ProductColourContainer>

                    <DiscountContainer>
                        <DiscountLabel>Discount Range</DiscountLabel>
                        <DiscountFilterContainer>
                            {discount.map((item, index) => (
                                <ProductDiscountList key={index}>
                                    <TickBoxContainer
                                        onClick={() => {
                                            toggleTick(item.id);
                                        }}
                                    >
                                        {isTicked === item.id ? (
                                            <TickBoxImage
                                                src={tick}
                                                alt="check box"
                                            />
                                        ) : (
                                            <TickBoxImage
                                                src={untick}
                                                alt="check box"
                                            />
                                        )}
                                    </TickBoxContainer>

                                    <ProductDicountLabel>
                                        {item.discound_range}
                                    </ProductDicountLabel>
                                </ProductDiscountList>
                            ))}
                        </DiscountFilterContainer>
                    </DiscountContainer>
                </LeftSideContainer>
            </SubContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    width: 32%;
    border-right: 1px solid #eeeeee;
    padding: 20px 35px 0px 0px;

    @media all and (max-width: 480px) {
        display: none;
    }
`;

const SubContainer = styled.div``;

const LeftSideContainer = styled.div`
    /* margin-right: 25px; */
    /* margin-top: 15px; */

    @media all and (max-width: 1280px) {
        width: 25%;
    }

    @media all and (max-width: 1150px) {
        width: 30%;
    }

    @media all and (max-width: 980px) {
        width: 35%;
    }

    @media all and (max-width: 768px) {
        width: 45%;
    }
    @media all and (max-width: 480px) {
        display: none;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    @media all and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

const FilterHeading = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }

    @media all and (max-width: 768px) {
        font-size: 16px;
    }
`;

const ClearHeading = styled.span`
    display: inline-block;
    color: #ff5e48;
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    cursor: pointer;

    @media all and (max-width: 768px) {
        font-size: 14px;
    }
`;

const FilterSearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #f4f4f4;
    padding: 7px 20px;
    border-radius: 30px;
    /* width: 100%; */

    @media all and (max-width: 768px) {
        padding: 10px 20px;
    }
`;

const FilterSearchField = styled.input`
    color: #777a86;
    font-size: 12px;
    font-family: "dm_sansregular";
`;

const SearchCloseImage = styled.div`
    width: 20px;
    cursor: pointer;
`;

const SerachClose = styled.img`
    width: 100%;
    display: block;
`;

const OccasionItems = styled.ul`
    margin-bottom: 25px;
`;

const OccasionsList = styled.li`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0px;
    }
`;

const ProductFilterSuggestion = styled.h1`
    color: #ff5e48;
    font-size: 16px;
    font-family: "dm_sansregular";
`;

const BrandContainer = styled.div`
    margin-bottom: 20px;
`;

const BrandLabelAndImage = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const BrandLabel = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }
`;

const BrandSearchContainer = styled.div`
    width: 30px;
    cursor: pointer;
`;

const BrandSerachImage = styled.img`
    width: 100%;
    display: block;
`;

const ProductBrandConntainer = styled.ul``;

const MajorBrands = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const BrandNameLabel = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-right: 2px;
`;

const BrandItemsCount = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const PrizeContainer = styled.div`
    margin-bottom: 20px;
`;

const ProductPrizeLabel = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 20px;

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }
`;

const ProductPrizeFilterContainer = styled.ul`
    margin-bottom: 20px;
`;

const ProductFilterPrize = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const PrizeLabel = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-right: 2px;
`;

const PrizeCount = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const MorePrizeLabel = styled.h1`
    color: #ff5e48;
    font-size: 16px;
    font-family: "dm_sansregular";
`;

const ProductColourContainer = styled.div`
    margin-bottom: 20px;
`;

const ColourLabel = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 20px;

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }
`;

const ColourFilterContainer = styled.ul``;

const ProductColours = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const BulletColour = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;

    background-color: ${({ type }) =>
        type === "Red"
            ? "#DF0707"
            : type === "Blue"
            ? "#0710DF"
            : type === "Black"
            ? "#090808"
            : type === "Green"
            ? "#25B800"
            : type === "White"
            ? "#FFFFFF"
            : ""};
`;

const ProductColour = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-right: 2px;
`;

const ColourCount = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const AdditionalColourCount = styled.h1`
    color: #ff5e48;
    font-size: 16px;
    font-family: "dm_sansboldmedium";
`;

const DiscountContainer = styled.div``;

const DiscountLabel = styled.h1`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 20px;

    @media all and (max-width: 1150px) {
        font-size: 18px;
    }
`;

const DiscountFilterContainer = styled.ul``;

const ProductDiscountList = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const ProductDicountLabel = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
`;

const TickBoxContainer = styled.div`
    width: 18px;
    cursor: pointer;
    margin-right: 10px;
`;

const TickBoxImage = styled.img`
    width: 100%;
    display: block;
`;

const OccasionLabel = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-right: 2px;
`;

const ProductsCountLabel = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
`;
