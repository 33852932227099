import React, { useEffect, useState } from "react";

//packages
import styled from "styled-components";
import ReactPaginate from "react-paginate";

//axios
import { dettConfig } from "../../axiosConfig";

//components
import DettLandingPageHeader from "../includes/header/DettLandingPageHeader";
import HomeFooterpage from "./home/HomeFooterpage";
import CareerDataLoader from "../general/loader/CareerDataLoader";

export default function Career() {
    //states
    const [careerData, setCareerData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [dataFetched, setDataFetched] = useState(false);
    const [dataFound, setDataFound] = useState(true);

    //pagination states
    const [currentPage, setCurrentPage] = useState(1); // set current page to 1
    const [totalPages, setTotalPages] = useState(null);

    //loader state
    const [loading, setLoading] = useState(false);

    //next,prev image hover state
    const [id, setId] = useState("");

    //setting search query
    const handleSearch = (e) => {
        let value = e.target.value;
        setSearchText(value.toLowerCase().trimStart());
    };

    //jobs data fetching api connection
    const searchJobs = () => {
        setLoading(true);
        dettConfig
            .get(`api/v1/careers/`, {
                params: {
                    page: currentPage,
                    q: searchText,
                },
            })
            .then((response) => {
                const { data, StatusCode, total_pages } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    setTotalPages(total_pages);
                    setCareerData(data);
                    setDataFetched(true);
                    setDataFound(true);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                    setDataFound(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    //enter key function(fetching data)
    const handleKeyDown = (e) => {
        if (searchText !== "") {
            if (e.key === "Enter") {
                searchJobs();
            }
        }
    };

    //fetching data when page changes
    useEffect(() => {
        searchJobs();
    }, [currentPage]);

    //fetching data when input field is cleared

    useEffect(() => {
        if (searchText === "") {
            searchJobs();
        }
    }, [searchText]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const selectedPage = event.selected + 1; // increment selected page by 1
        setCurrentPage(selectedPage); // update current page state
    };

    //sending mail function
    const handleMail = (jobRole) => {
        const emailId = "Careers@dett.app";
        const subject = `Application for ${jobRole}`;
        const body = "";
        const to = emailId;
        const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    //changing next,prev arrows when hovering
    const toggleArrows = (selected) => {
        setId(selected);
    };

    const colors = [
        "#FF9DEF",
        "#4DC8AF",
        "#49B7E1",
        "#998CE2",
        "#E8994C",
        "#73CC7D",
        "#EFD02B",
        "#EF432B",
    ];

    return (
        <>
            <DettLandingPageHeader />
            <Container>
                <Wrapper className="wrapper">
                    {!dataFound ? (
                        <NoOpeningsFound>
                            <Heading>Careers</Heading>
                            <TopHeading>
                                With a team of creative professionals to design, develop and market
                                a range of gift products, Join with us now!
                            </TopHeading>
                            <MiddleBox>
                                <SearchContainer>
                                    <InputContainer>
                                        <SearchIconContainer>
                                            <MagnifierImg
                                                src={
                                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/search.svg"
                                                }
                                                alt="Search"
                                            />
                                        </SearchIconContainer>
                                        <SearchInput
                                            type="text"
                                            placeholder="Search jobs..."
                                            onChange={handleSearch}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </InputContainer>
                                    <SearchButton onClick={() => searchJobs()}>Search</SearchButton>
                                </SearchContainer>
                            </MiddleBox>
                            <NoOpeningsImage>
                                <img
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/no-opening.png"
                                    }
                                    alt="no-openings"
                                />
                            </NoOpeningsImage>
                            <NoOpeningTitle>Sorry no openings right now</NoOpeningTitle>
                            <Para>Keep visiting careers, we will update new openings</Para>
                        </NoOpeningsFound>
                    ) : (
                        <>
                            {searchText !== "" && careerData.length === 0 && dataFetched ? (
                                <NoResultBox>
                                    <Title>
                                        You searched for <span>{searchText}</span>
                                    </Title>
                                    <SearchIcon>
                                        <img
                                            src={
                                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/13-04-2023/search.svg"
                                            }
                                            alt="search"
                                        />
                                    </SearchIcon>
                                    <Paragraph>We couldn’t find any matches !</Paragraph>
                                    <SmallParagraph>
                                        Please check the spelling or try searching something else
                                    </SmallParagraph>
                                    <SearchContainer>
                                        <InputContainer>
                                            <SearchIconContainer>
                                                <MagnifierImg
                                                    src={
                                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/search.svg"
                                                    }
                                                    alt="Search"
                                                />
                                            </SearchIconContainer>
                                            <SearchInput
                                                type="text"
                                                placeholder="Search jobs..."
                                                onChange={handleSearch}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </InputContainer>
                                        <SearchButton onClick={() => searchJobs()}>
                                            Search
                                        </SearchButton>
                                    </SearchContainer>
                                </NoResultBox>
                            ) : (
                                <>
                                    <TopSection>
                                        <Heading>Careers</Heading>
                                        <Description>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            Nibh ipsum urna, adipiscing dolor sit Lorem ipsum dolor
                                            sit amet, consectetur adipiscing elit.
                                        </Description>
                                        <SearchContainer>
                                            <InputContainer>
                                                <SearchIconContainer>
                                                    <MagnifierImg
                                                        src={
                                                            "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/search.svg"
                                                        }
                                                        alt="Search"
                                                    />
                                                </SearchIconContainer>
                                                <SearchInput
                                                    type="text"
                                                    placeholder="Search jobs..."
                                                    onChange={handleSearch}
                                                    onKeyDown={handleKeyDown}
                                                    value={searchText}
                                                />
                                            </InputContainer>
                                            <SearchButton onClick={() => searchJobs()}>
                                                Search
                                            </SearchButton>
                                        </SearchContainer>
                                    </TopSection>
                                    <BottomSection>
                                        {loading || careerData.length === 0 ? (
                                            <CareerDataLoader />
                                        ) : (
                                            <JobCardList>
                                                {careerData.map((item, index) => (
                                                    <Card key={index}>
                                                        <Top>
                                                            <JobLogoContainer>
                                                                <JobLogo
                                                                    src={item.company_image}
                                                                    alt="Job-logo"
                                                                />
                                                            </JobLogoContainer>
                                                        </Top>
                                                        <Middle>
                                                            <DotAndLabelContainer>
                                                                <DotColor
                                                                    style={{
                                                                        backgroundColor:
                                                                            colors[
                                                                                index %
                                                                                    colors.length
                                                                            ],
                                                                    }}
                                                                ></DotColor>
                                                                <JobName>{item.role}</JobName>
                                                            </DotAndLabelContainer>

                                                            <WorkContainer>
                                                                <WorkingHourLabel>
                                                                    {item.working_hours
                                                                        .substring(0, 1)
                                                                        .toUpperCase() +
                                                                        item.working_hours
                                                                            .substring(1)
                                                                            .replace("_", " ")}
                                                                </WorkingHourLabel>
                                                                <WorkingTypeLabel>
                                                                    {item.type
                                                                        .substring(0, 1)
                                                                        .toUpperCase() +
                                                                        item.type
                                                                            .substring(1)
                                                                            .replace("_", " ")}
                                                                </WorkingTypeLabel>
                                                            </WorkContainer>
                                                            <JobDescription>
                                                                {item.description}
                                                            </JobDescription>
                                                        </Middle>
                                                        <Bottom>
                                                            <ApplyButton
                                                                onClick={() =>
                                                                    handleMail(item.role)
                                                                }
                                                            >
                                                                Apply Now
                                                            </ApplyButton>
                                                        </Bottom>
                                                    </Card>
                                                ))}
                                            </JobCardList>
                                        )}

                                        <ArrowButtonContainer loading={loading}>
                                            {careerData?.length > 1 && (
                                                <ReactPaginate
                                                    previousLabel={
                                                        <ButtonContainer
                                                            onMouseEnter={() =>
                                                                toggleArrows("grey-prev")
                                                            }
                                                            onMouseLeave={() =>
                                                                toggleArrows("normal-prev")
                                                            }
                                                        >
                                                            {id === "grey-prev" ? (
                                                                <PreviousButton
                                                                    src={
                                                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/slider-prev-grey.svg"
                                                                    }
                                                                />
                                                            ) : (
                                                                <PreviousButton
                                                                    src={
                                                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/slider-prev-white.svg"
                                                                    }
                                                                />
                                                            )}
                                                        </ButtonContainer>
                                                    }
                                                    nextLabel={
                                                        <ButtonContainer
                                                            onMouseEnter={() =>
                                                                toggleArrows("grey-next")
                                                            }
                                                            onMouseLeave={() =>
                                                                toggleArrows("normal-next")
                                                            }
                                                        >
                                                            {id === "grey-next" ? (
                                                                <NextButton
                                                                    src={
                                                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/slider-next-grey.svg"
                                                                    }
                                                                />
                                                            ) : (
                                                                <NextButton
                                                                    src={
                                                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/slider-next-white.svg"
                                                                    }
                                                                />
                                                            )}
                                                        </ButtonContainer>
                                                    }
                                                    onPageChange={handlePageClick}
                                                    hidePageNumbers={true}
                                                    pageCount={Math.ceil(totalPages)}
                                                    renderOnZeroPageCount={null}
                                                />
                                            )}
                                        </ArrowButtonContainer>
                                    </BottomSection>
                                </>
                            )}
                        </>
                    )}
                </Wrapper>
            </Container>
            <HomeFooterpage />
        </>
    );
}

const Container = styled.section`
    padding: 130px 0px 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    @media all and (max-width: 980px) {
        padding-top: 100px;
    }
`;
const Wrapper = styled.section``;

//NO-OPENINGS-FOUND SECTION STYLES
const NoOpeningsFound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px 0 100px 0;
`;

const TopHeading = styled.h2`
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
    width: 65%;
    text-align: center;
    display: flex;
    justify-content: center;
    @media all and (max-width: 1180px) {
        width: 75%;
    }
    @media all and (max-width: 1080px) {
        width: 85%;
    }
    @media all and (max-width: 1080px) {
        width: 95%;
    }
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 900px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;

const NoOpeningsImage = styled.div`
    margin-bottom: 25px;
    width: 160px;
    @media all and (max-width: 540px) {
        width: 120px;
    }
`;
const NoOpeningTitle = styled.h3`
    margin-bottom: 5px;
    color: #282c3f;
    font-size: 20px;
    font-family: "dm_sansboldmedium";
`;
const Para = styled.p`
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
    @media all and (max-width: 480px) {
        width: 90%;
        margin: 0 auto;
        text-align: center;
    }
`;

const MiddleBox = styled.div`
    width: 65%;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    @media all and (max-width: 1080px) {
        width: 75%;
    }
    @media all and (max-width: 980px) {
        width: 85%;
    }
`;

//No-RESULT BOX STYLES
const NoResultBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    padding: 20px 0 80px;
    @media all and (max-width: 1200px) {
        width: 60%;
    }

    @media all and (max-width: 1080px) {
        width: 70%;
    }

    @media all and (max-width: 980px) {
        width: 80%;
    }
    @media all and (max-width: 850px) {
        width: 90%;
    }

    @media all and (max-width: 768px) {
        width: 80%;
    }

    @media all and (max-width: 640px) {
        width: 90%;
    }

    @media all and (max-width: 540px) {
        width: 95%;
    }

    @media all and (max-width: 480px) {
        padding: 0 0 20px 0;
    }
`;

const Title = styled.h5`
    color: #777a86;
    font-size: 14px;
    margin-bottom: 15px;
    span {
        color: #4065e0;
        font-family: "dm_sansboldmedium";
    }
`;
const SearchIcon = styled.div`
    margin-bottom: 8px;
    width: 200px;
    margin-bottom: 20px;
    @media all and (max-width: 480px) {
        width: 150px;
    }
`;
const Paragraph = styled.p`
    color: #282c3f;
    font-size: 16px;
`;
const SmallParagraph = styled.p`
    color: #777a86;
    margin-bottom: 25px;
    font-size: 14px;
    @media all and (max-width: 550px) {
        text-align: center;
    }
    @media all and (max-width: 450px) {
        width: 80%;
    }
`;

// CAREER DATA SECTION STYLES
const TopSection = styled.div`
    width: 60%;
    margin: 0 auto 50px;

    @media all and (max-width: 1200px) {
        width: 70%;
    }
    @media all and (max-width: 1080px) {
        width: 80%;
    }

    @media all and (max-width: 980px) {
        width: 90%;
    }
`;
const Heading = styled.h2`
    color: #282c3f;
    font-family: "dm_sansboldbold";
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;

    @media all and (max-width: 480px) {
        font-size: 32px;
    }
`;
const Description = styled.p`
    color: #777a86;
    font-family: "dm_sansboldmedium";
    text-align: center;
    font-size: 14px;
    margin-bottom: 25px;
    line-height: 1.2em;
`;
const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eeeeee;
    padding: 3px;
    border-radius: 30px;
    width: 80%;
    margin: 0 auto;
    height: 48px;
    position: relative;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 480px) {
        height: 44px;
    }
`;
const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    height: 100%;
    width: 100%;
`;
const SearchIconContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`;
const MagnifierImg = styled.img`
    width: 100%;
    display: block;
`;
const SearchInput = styled.input`
    color: #282c3f;
    font-family: "dm_sansregular";
    font-size: 14px;
    height: 100%;
    width: 100%;
    ::placeholder {
        color: #b4bcc7;
    }
`;
const SearchButton = styled.div`
    background: #ff5e48;
    font-family: "dm_sansregular";
    font-size: 16px;
    color: #fff;
    padding: 14px 30px;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
    height: 42px;
    &:hover {
        background: linear-gradient(180deg, #ff7967 0%, #cd2b15 100%);
    }

    @media all and (max-width: 1080px) {
        font-size: 14px;
    }

    @media all and (max-width: 550px) {
        padding: 10px 25px;
    }

    @media all and (max-width: 480px) {
        height: 38px;
    }
`;
const BottomSection = styled.div``;
const JobCardList = styled.ul`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 80px;

    @media all and (max-width: 1080px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media all and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 60px;
    }

    @media all and (max-width: 480px) {
        margin-bottom: 45px;
    }
`;
const Card = styled.li`
    box-shadow: -10px 30px 61px rgba(80, 80, 80, 0.09);
    padding: 30px;
    background: #ffffff;
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;
const JobLogoContainer = styled.div`
    width: 50px;
    margin-right: 10px;
    padding: 5px;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
`;
const JobLogo = styled.img`
    width: 100%;
    display: block;
`;

const Middle = styled.div``;

const WorkContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const WorkingHourLabel = styled.div`
    background: #e5fff6;
    padding: 8px 15px;
    border-radius: 5px;
    margin-right: 10px;
    color: #009262;
    font-family: "dm_sansboldmedium";
    font-size: 14px;
`;

const WorkingTypeLabel = styled.div`
    background: #e5ecff;
    padding: 8px 20px;
    border-radius: 5px;
    color: #5c82ea;
    font-family: "dm_sansboldmedium";
    font-size: 14px;
`;

const DotAndLabelContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const DotColor = styled.span`
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 50px;
    margin-right: 10px;
`;
const JobName = styled.h6`
    font-size: 16px;
    color: #282c3f;
    font-family: "dm_sansboldbold";
    @media all and (max-width: 1280px) {
        font-size: 15px;
        white-space: nowrap;
    }

    @media all and (max-width: 980px) {
    }
`;

const JobDescription = styled.p`
    font-size: 14px;
    font-family: "dm_sansregular";
    color: #777a86;
    margin-bottom: 20px;
    height: 67px;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
const Bottom = styled.div`
    border: 1px solid #ff5e48;
    padding: 9px 30px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    &:hover {
        transition: all 0.5s ease-in-out;
        background-color: #ff5e48;
        span {
            color: #fff;
        }
    }

    @media all and (max-width: 768px) {
        background-color: #ff5e48;
    }

    @media all and (max-width: 360px) {
        padding: 8px 20px;
    }
`;
const ApplyButton = styled.span`
    display: inline-block;
    font-family: "dm_sansboldmedium";
    font-size: 14px;
    color: #ff5e48;
    transition: all 0.5s ease-in-out;
    @media all and (max-width: 768px) {
        color: #fff;
    }
`;

// PAGINATION BUTTON BOX STYLES
const ArrowButtonContainer = styled.div`
    display: ${({ loading }) => (loading ? "none" : "block")} !important;
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        li {
            display: none;
        }
        li.previous {
            width: 25px;
            display: block;
            margin-right: 10px;
        }
        li.next {
            display: block;
            width: 25px;
        }
    }
    width: 10%;
    display: flex;
    align-items: center;
    margin: 0 auto;

    @media all and (max-width: 1150px) {
        width: 15%;
    }

    @media all and (max-width: 640px) {
        width: 25%;
    }

    @media all and (max-width: 320px) {
        width: 30%;
    }
`;

const ButtonContainer = styled.div`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    width: 40px;
`;

const PreviousButton = styled.img``;

const NextButton = styled.img``;
