import React, { useState } from "react";
import styled from "styled-components";

export default function ProductSortPage({ showSortList, handleSortModal }) {
    return (
        <MainContainer>
            {showSortList ? (
                <Overlay onClick={() => handleSortModal()}></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: showSortList && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <SortLabel>Sort By</SortLabel>

                        <SortTypeContainer>
                            <SortLabelOne>Low to High</SortLabelOne>
                            <SortLabelOne>High to Low</SortLabelOne>
                            <SortLabelOne>Low to High</SortLabelOne>
                        </SortTypeContainer>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    display: none;

    @media all and (max-width: 480px) {
        display: block;
    }
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    margin: 0 auto;
    right: 0;
    z-index: 1000;
    left: 0;
    top: 50%;
`;

const Overlay = styled.div`
    background: #fff;
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const Modal = styled.div`
    min-height: 40vh;
    width: 100%;
    padding: 40px;
    background: #d9d9d9;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    translate: 0 200px;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #f5f5f6;

    @media all and (max-width: 360px) {
        min-height: 35vh;
    }
`;

const Container = styled.div``;

const SortLabel = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 20px;
`;

const SortTypeContainer = styled.div``;

const SortLabelOne = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 10px;

    :hover {
        color: #ff5e48;
    }
`;
