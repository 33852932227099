import React, { useState } from "react";

//packages-------
import styled from "styled-components";

//images------
import gifImg from "../../../assets/images/gift-images/ShopGiftImg.png";

//components------
import HomeFooterpage from "../../../components/screens/home/HomeFooterpage";
import { Link } from "react-router-dom";

export default function CorporateGift() {

    const [hover, setHover] = useState("");
    return (
        <>
            <MainContainer>
                <SubContainer className="wrapper">
                    <Container>
                        <TopContainer>
                            <Label>
                                Corporate <Small>Gifting's</Small>
                            </Label>
                        </TopContainer>
                        <BottomContainer>
                            <MainImageContainer
                            onMouseOver={() => setHover("hover")}
                            onMouseOut={() => setHover("")}
                            >
                                <MainCorporateImg
                                    src={
                                        require("../../../assets/images/corporate-gift/corporateImage.svg")
                                            .default
                                    }
                                    alt="corporate image"
                                />
                                {hover == "hover" ? (
                                <ShopContainer to="/corporategift" >
                                    <ShopNowLabel>Shop Now</ShopNowLabel>
                                    <GiftImage>
                                        <GiftImg src={gifImg} />
                                    </GiftImage>
                                </ShopContainer>
                            ) : null}
                            </MainImageContainer>
                        </BottomContainer>
                    </Container>
                </SubContainer>
            </MainContainer>
            <HomeFooterpage />
        </>
    );
}

const MainContainer = styled.div`
    padding-bottom: 25px;
    border-bottom: 1px solid #eeeeee;
`;

const SubContainer = styled.div``;

const Container = styled.div``;

const TopContainer = styled.div`
    margin-bottom: 25px;
`;

const Label = styled.h4`
    font-size: 28px;
    font-family: "dm_sansboldMedium";

    @media all and (max-width: 640px) {
        font-size: 24px;
    }
`;

const Small = styled.span`
    font-size: 28px;
    color: #ff5e48;
    font-family: "dm_sansboldMedium";

    @media all and (max-width: 640px) {
        font-size: 24px;
    }
`;

const BottomContainer = styled.div`
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.12);
    padding: 25px;
    border-radius: 20px;

    @media all and (max-width: 640px) {
        padding: 15px;
    }
`;

const MainImageContainer = styled.div`
    position: relative;

    &.hover {

    }
`;

const MainCorporateImg = styled.img`
    width: 100%;
    display: block;
`;

const ShopContainer = styled(Link)`
    position: absolute;
    top: 42%;
    height: 50px;
    width: 100%;
    background: #ffffff60;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    transition: 0.4s ease;
    cursor: pointer;
`;

const ShopNowLabel = styled.h2`
    font-size: 18px;
    color: #ffff;
    font-family: "dm_sansboldmedium";
`;

const GiftImage = styled.div`
    filter: invert(100);
`;

const GiftImg = styled.img``;