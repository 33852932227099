import React from "react";
import GiftSpotlight from "../../includes/gift/GiftSpotlight";
import GiftTrendingImage from "../../includes/gift/GiftTrendingImage";
import GiftNewcollection from "../../includes/gift/GiftNewcollection";
import CorporateGift from "../../includes/gift/CorporateGift";
import HomeFooterpage from "../home/HomeFooterpage";
import GiftErrorPage from "../../includes/gift/GiftErrorPage";

//components

function GiftLandingPage() {
    return (
        <>
            <GiftSpotlight />
            <GiftTrendingImage />
            <GiftNewcollection />
            <CorporateGift />
            {/* <GiftErrorPage /> */}
            {/* <HomeFooterpage /> */}
        </>
    );
}

export default GiftLandingPage;
