import React, { useState, useEffect } from "react";

function PdfViewer() {
    //pdf state
    const [pdfUrl, setPdfUrl] = useState(null);

    //pdf fetching api
    const fetchPdf = async () => {
        const response = await fetch("https://developers-dett-prebooking.talrop.works/api/v1/web/download/");
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
    };

    useEffect(() => {
        fetchPdf();
    }, []);

    return (
        <div style={{ width: "100%", height: "100vh", margin: "0 auto" }}>
            {pdfUrl && (
                <embed
                    src={pdfUrl}
                    type="application/pdf"
                    style={{ width: "100%", height: "100%" }}
                />
            )}
        </div>
    );
}

export default PdfViewer;
