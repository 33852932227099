import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function ContactUs() {
    return (
        <MainContainer className="main-container">
            <TopContainer>
                <TitleBox>
                    <LeftArrowBox to={"/account"}>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/left-arrow.svg"
                            }
                            alt="left-arrow"
                        />
                    </LeftArrowBox>
                    <PageTitle>Contact Us</PageTitle>
                </TitleBox>
            </TopContainer>
            <BottomContainer>
                <TopPortion>
                    <LeftSection>
                        <HeadingContainer>
                            <Title>Help Center</Title>
                            <ContactImgContainer>
                                <img
                                    src={
                                        require("../../../assets/images/product-single/headphone.svg")
                                            .default
                                    }
                                    alt="Head-Phone"
                                />
                            </ContactImgContainer>
                        </HeadingContainer>
                        <ContactDescription>
                            We are happy to help you
                        </ContactDescription>
                    </LeftSection>
                    <RightSection>
                        <TrackButton>Track&nbsp;Orders</TrackButton>
                    </RightSection>
                </TopPortion>
                <MiddlePortion>
                    <ContactButton>
                        <span>Email Us&nbsp;:</span>&nbsp;detthelp@gmail.com
                    </ContactButton>
                    <ContactButton>
                        <span>Contact Us&nbsp;:</span>&nbsp;+91-9856895689
                    </ContactButton>
                </MiddlePortion>
                <BottomPortion>
                    <FAQTitleContainer>
                        <FAQTitle>Frequently Asked Questions</FAQTitle>
                        <QuestionMarkContainer>
                            <img
                                src={
                                    require("../../../assets/images/product-single/question-mark.svg")
                                        .default
                                }
                                alt="Question-mark"
                            />
                        </QuestionMarkContainer>
                    </FAQTitleContainer>
                    <QuestionList>
                        <FAQContainer>
                            <FAQuestion>
                                Why are there different prices for the same
                                product? Is it legal?
                            </FAQuestion>
                            <RightArrowContainer>
                                <img
                                    src={
                                        require("../../../assets/images/product-single/faq-arrow.svg")
                                            .default
                                    }
                                    alt="Right-Arrow"
                                />
                            </RightArrowContainer>
                        </FAQContainer>
                        <FAQContainer>
                            <FAQuestion>
                                Why are there different prices for the same
                                product? Is it legal?
                            </FAQuestion>
                            <RightArrowContainer>
                                <img
                                    src={
                                        require("../../../assets/images/product-single/faq-arrow.svg")
                                            .default
                                    }
                                    alt="Right-Arrow"
                                />
                            </RightArrowContainer>
                        </FAQContainer>
                        <FAQContainer>
                            <FAQuestion>
                                Why are there different prices for the same
                                product? Is it legal?
                            </FAQuestion>
                            <RightArrowContainer>
                                <img
                                    src={
                                        require("../../../assets/images/product-single/faq-arrow.svg")
                                            .default
                                    }
                                    alt="Right-Arrow"
                                />
                            </RightArrowContainer>
                        </FAQContainer>
                    </QuestionList>
                </BottomPortion>
            </BottomContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #f8f8f8;
    width: 100%;
    padding: 20px;
    border-radius: 10px;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        background: #fff;
        padding: 0;
        transition: all 0.5s ease-in-out;
        //increasing height when editing input(when save button appears)
        padding-bottom: ${({ isEdit }) =>
            isEdit === null || isEdit === "EMAIL" || isEdit === "CANCEL"
                ? "0"
                : "50px"};
    }

    @media all and (max-width: 360px) {
        padding: 10px;
        padding-bottom: ${({ isEdit }) =>
            isEdit === null || isEdit === "EMAIL" || isEdit === "CANCEL"
                ? "0"
                : "50px"};
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
        padding: 20px;
    }
`;
const TitleBox = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    padding: 20px 10px;
    margin-bottom: 20px;
`;

const LeftArrowBox = styled(Link)`
    cursor: pointer;
    width: 25px;
`;
const PageTitle = styled.h2`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-left: 10px;
    color: #0a0a0a;
`;
const BottomContainer = styled.div`
    @media all and (max-width: 480px) {
        width: 85%;
        margin: 0 auto;
        margin-top: 10px;
    }
`;
const TopPortion = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 360px) {
        flex-wrap: wrap;
    }
`;
const LeftSection = styled.div``;
const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const Title = styled.h3`
    font-family: "dm_sansboldbold";
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
`;
const ContactImgContainer = styled.div``;
const ContactDescription = styled.p`
    color: #777a86;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const RightSection = styled.div`
    
    @media all and (max-width: 360px) {
        margin-top: 10px;
        width: 100%;
    }
`;
const TrackButton = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    color: #fff;
    padding: 10px 30px;
    border-radius: 8px;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        padding: 10px 15px;
    }
`;
const MiddlePortion = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 70px 0 40px;
    @media all and (max-width: 1280px) {
        flex-wrap: wrap;
        margin: 50px 0 40px;
    }
`;
const ContactButton = styled.div`
    font-family: "dm_sansboldmedium";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 6px 56px rgba(255, 255, 255, 0.04);
    border-radius: 8px;
    span {
        color: #ff5e48;
    }
    @media all and (max-width: 1280px) {
        width: 100%;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const BottomPortion = styled.div``;
const FAQTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
`;
const FAQTitle = styled.h4`
    font-family: "dm_sansboldmedium";
`;
const QuestionMarkContainer = styled.div``;
const QuestionList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;
const FAQContainer = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    background: #fff;
`;
const FAQuestion = styled.h5`
    font-family: "dm_sansboldmedium";
`;
const RightArrowContainer = styled.div`
    width: 25px;
`;
