import React, { useEffect, useState } from "react";

//packages
import { Fade } from "react-reveal";
import styled from "styled-components";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

//components
import ReelModal from "../../includes/modal/ReelModal";
import PostModal from "../../includes/modal/PostModal";
import CareerDataLoader from "../../general/loader/CareerDataLoader";

//custom hooks
import useOutsideScroll from "../../general/hooks/useOutsideScroll";

//axios
import { dettConfig } from "../../../axiosConfig";

function Instagram() {
    const initialData = [];

    //selectedCategory(POST/REEL)
    const [selected, setSelected] = useState("Post");

    //instagram feed state (posts and reels)
    const [feeds, setFeedsData] = useState(initialData);

    //access token for fetching instagram  posts and reels
    const [accessToken, setAccessToken] = useState(
        "IGQVJYVDJBTFU5Q3Y2cUFkTzQwMjQ0MW5Pc3FhQ3E4UVg3ZAGpHX0NZAb0ZA3MU55WDZAHX3dqTDhPNnFnVnl4cjk0Wi0zeWdjbW5SUFRTbFlPVWVQVWMxdncxMmotSENkNkVLQy0zZAUgzNDJXSVVvZAmlMVgZDZD"
    );

    //selectedReel & reelmodal states
    const [selectedReel, setSelectedReel] = useState("");
    const [isModal, setModal] = useState(false);

    //selectedPost & postmodal states
    const [selectedPost, setSelectedPost] = useState("");
    const [imageModal, setImageModal] = useState(false);

    //scroll pagination states
    const [hasMore, setHasMore] = useState(true);
    const [nextPageUrl, setNextPageUrl] = useState(null);

    //cutom hooks
    useOutsideScroll(imageModal);
    useOutsideScroll(isModal);

    //instagram access token fetching api
    useEffect(() => {
        dettConfig
            .get("api/v1/web/instagram-graph/access-token/")
            .then((response) => {
                const { StatusCode, data } = response?.data;
                if (StatusCode === 6000) {
                    setAccessToken(data?.accessToken);
                }
            })
            .catch((error) => {});
    }, []);

    useEffect(() => {
        //this is to avoid memory leaks
        const abortController = new AbortController();

        //fetching posts & reels
        fetchInstagramPost();

        return () => {
            //cancel pending fetch request on component unmount
            abortController.abort();
        };
    }, []);

    //fetch instagram posts api
    const fetchInstagramPost = async () => {
        try {
            const response = await axios.get(
                `https://graph.instagram.com/me/media?fields=id,user_id,media_type,media_url,thumbnail_url,product_tags,caption&access_token=${accessToken}&limit=15${
                    nextPageUrl ? `&after=${nextPageUrl}` : ""
                }`
            );
            if (response) {
                setFeedsData([...feeds, ...response?.data?.data]);
                setHasMore(!!response.data?.paging?.next);
                setNextPageUrl(response.data?.paging?.cursors?.after);
            }
        } catch (err) {
            console.error(err);
        }
    };

    //fetching next page-data
    const handleLoadMore = () => {
        if (hasMore) {
            fetchInstagramPost();
        }
    };

    //category list[POST/REEL]
    const categories = [
        {
            id: 1,
            title: "Post",
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/postblack.svg",
            selected_image:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/post.svg",
        },
        {
            id: 2,
            title: "Reel",
            image: "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/reel.svg",
            selected_image:
                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/reelred.svg",
        },
    ];

    return (
        <div>
            <MainContainer className="wrapper">
                <Fade
                    bottom
                    cascade
                >
                    <TitleDiv>
                        <div>
                            <InstagramDiv>
                                <InstagramImage
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/Instagram-white.svg"
                                    }
                                    alt="instagram"
                                />
                            </InstagramDiv>
                        </div>
                        <Title>
                            Follow us on <Span>Instagram</Span>
                        </Title>
                    </TitleDiv>
                    <TitleDescribtion>
                        One-Stop online shop for all your gifting needs. Follow us to know more &
                        stay connected. Get perfect gifting ideas for your friends & family.
                    </TitleDescribtion>

                    <TotalMain>
                        <TopBox>
                            {categories.map((category) => (
                                <MediaContainer
                                    key={category.id}
                                    onClick={() => setSelected(category.title)}
                                    className={selected === category.title ? "active" : ""}
                                >
                                    <InstaContainer>
                                        <Insta
                                            src={
                                                selected === category.title
                                                    ? category.selected_image
                                                    : category.image
                                            }
                                            alt="img"
                                        />
                                    </InstaContainer>
                                    <Text>{category.title}</Text>
                                </MediaContainer>
                            ))}
                        </TopBox>

                        <DivTotal>
                            {selected === "Post"
                                ? feeds
                                      .filter((data) => data.media_type === "IMAGE")
                                      .map((item, index) => (
                                          <PostContainer key={index}>
                                              <PostimageContainer
                                                  onClick={() => {
                                                      setImageModal(true);
                                                      setSelectedPost(item);
                                                  }}
                                              >
                                                  <Postgift
                                                      src={item.media_url}
                                                      alt="image"
                                                  />
                                              </PostimageContainer>
                                          </PostContainer>
                                      ))
                                : feeds
                                      .filter((data) => data.media_type === "VIDEO")
                                      .map((item, index) => (
                                          <PostContainer key={index}>
                                              <PostimageContainer classname="videocontainer">
                                                  <Postgift
                                                      src={item.thumbnail_url}
                                                      alt="thumbnail"
                                                  />
                                                  <PlayImage
                                                      onClick={() => {
                                                          setModal(true);
                                                          setSelectedReel(item);
                                                      }}
                                                  >
                                                      <img
                                                          src={
                                                              "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/playbutton.svg"
                                                          }
                                                          alt="play button"
                                                      />
                                                  </PlayImage>
                                              </PostimageContainer>
                                          </PostContainer>
                                      ))}
                            <InfiniteScroll
                                dataLength={feeds.length}
                                next={handleLoadMore}
                                hasMore={hasMore}
                                loader={<CareerDataLoader />}
                            ></InfiniteScroll>
                        </DivTotal>
                    </TotalMain>
                </Fade>
                <ReelModal
                    isModal={isModal}
                    setModal={setModal}
                    reel={feeds}
                    selectedReel={selectedReel}
                    setSelectedReel={setSelectedReel}
                />
            </MainContainer>

            <PostModal
                imageModal={imageModal}
                setImageModal={setImageModal}
                selectedPost={selectedPost}
                setSelectedPost={setSelectedPost}
            />
        </div>
    );
}

const DivTotal = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    /* height: 400px; */
    overflow-y: scroll;
    background-color: #fff;
    //future
    position: sticky;
    top: 0;
    /* z-index: 100; */
    height: 600px;
    /* overflow-y: scroll;s */
    /* padding-bottom: 35px; */
    @media all and (max-width: 980px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media all and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media all and (max-width: 480px) {
        grid-gap: 10px;
    }
`;
const TopBox = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #fff;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    width: 95%;
    height: 52px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 25px;
    padding: 10px;

    @media all and (max-width: 980px) {
        margin: 20px 0;
        width: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 10px;
        grid-template-columns: 1fr 1fr;
        height: 49px;
    }
`;

const MainContainer = styled.div`
    padding: 0 0 80px 0;

    //future
    /* height: 100vh;
    position: relative; */

    @media all and (max-width: 640px) {
        padding: 0 0 60px 0;
    }
    @media all and (max-width: 480px) {
        padding: 0 0 50px 0;
    }
`;
const PlayImage = styled.div`
    position: absolute;
    left: 42%;
    top: 50%;
    background: grey;
    border-radius: 50%;
    cursor: pointer;

    @media all and (max-width: 768px) {
        left: 35%;
        top: 46%;
        width: 35px;
    }

    @media all and (max-width: 640px) {
        left: 40%;
    }
`;
const TitleDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    div {
        background: #ffffff;
        box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.18);
        border-radius: 14px;
    }
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
        flex-direction: column;
    }
`;
const InstagramDiv = styled.div`
    width: 40px;
    @media all and (max-width: 640px) {
        width: 35px;
    }
    @media all and (max-width: 480px) {
        width: 30px;
    }
`;
const Span = styled.span`
    font-size: 38px;
    font-family: "dm_sansboldbold";
    @media all and (max-width: 769px) {
        font-size: 30px;
    }
    @media all and (max-width: 480px) {
        font-size: 25px;
        display: inline-block;
        width: 100%;
    }

    background-image: linear-gradient(
        180deg,
        hsl(274deg 94% 51%) 0%,
        hsl(294deg 79% 45%) 28%,
        hsl(323deg 76% 50%) 52%,
        hsl(354deg 100% 58%) 71%,
        hsl(19deg 97% 59%) 85%,
        hsl(34deg 94% 56%) 94%,
        hsl(46deg 100% 48%) 100%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const TitleDescribtion = styled.p`
    color: #777a86;
    font-size: 15px;
    width: 63%;
    text-align: center;
    font-family: "dm_sansboldmedium";
    line-height: 20px;
    margin-bottom: 20px;

    margin: 0 auto;
    @media all and (max-width: 769px) {
        width: 100%;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const InstagramImage = styled.img`
    width: 100%;
    display: block;
`;
const Title = styled.h1`
    font-size: 38px;
    color: #282c3f;
    font-family: "dm_sansboldbold";
    margin-left: 20px;
    @media all and (max-width: 769px) {
        font-size: 30px;
    }
    @media all and (max-width: 680px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 26px;
        text-align: center;
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
`;
const TotalMain = styled.div``;
const MediaContainer = styled.div`
    position: relative;
    // future
    /* width: 32%; */
    width: 48%;
    cursor: pointer;
    height: 39px;
    border-radius: 3px;
    display: flex;

    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    &.active {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.07);
        transition: all 0.3s ease-in;
    }

    @media all and (max-width: 640px) {
        height: 33px;
    }
    @media all and (max-width: 360px) {
        height: 34px;
    }
`;
const InstaContainer = styled.div`
    width: 20px;
    margin-right: 10px;
    @media all and (max-width: 640px) {
        width: 18px;
    }
    @media all and (max-width: 360px) {
        width: 15px;
    }
`;
const Insta = styled.img`
    width: 100%;
    display: block;
`;
const Text = styled.span`
    font-size: 16px;
    color: #282c3f;
    font-family: "dm_sansregular";
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;

const PostContainer = styled.div``;
const PostimageContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    border-radius: 5px;

    video {
        width: 100%;
        height: auto;
    }
    &.videocontainer {
        video {
            width: 220px;
            height: 140px;
        }
    }
`;
const Postgift = styled.img`
    width: 100%;
    display: block;
    border-radius: 10px;
    height: 100% !important;
    cursor: pointer;
`;

export default Instagram;
