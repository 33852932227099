import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function PaymentOptions() {
    return (
        <MainContainer className="main-container">
            <TopContainer>
                <TitleBox>
                    <LeftArrowBox to={"/account"}>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/left-arrow.svg"
                            }
                            alt="left-arrow"
                        />
                    </LeftArrowBox>
                    <PageTitle>Payment Options</PageTitle>
                </TitleBox>
            </TopContainer>
            <BottomContainer>
                <ManageTitle>Manage Saved UPI’s</ManageTitle>
                <PaymentOptionsList>
                    <PaymentCard>
                        <UPIProviderInfo>
                            <ProviderName>Phonepe UPI</ProviderName>
                            <CustomerInfo>
                                <ProviderLogo>
                                    <img
                                        src={require("../../../assets/images/product-single/phonepe-logo.png")}
                                        alt="Provider-logo"
                                    />
                                </ProviderLogo>
                                <CustomerDetails>
                                    +91 9539730011@ybl
                                </CustomerDetails>
                            </CustomerInfo>
                        </UPIProviderInfo>
                        <DeleteIconContainer>
                            <img
                                src={
                                    require("../../../assets/images/product-single/delete-icon.svg")
                                        .default
                                }
                                alt="delete-icon"
                            />
                        </DeleteIconContainer>
                    </PaymentCard>
                    <PaymentCard>
                        <UPIProviderInfo>
                            <ProviderName>Phonepe UPI</ProviderName>
                            <CustomerInfo>
                                <ProviderLogo>
                                    <img
                                        src={require("../../../assets/images/product-single/phonepe-logo.png")}
                                        alt="Provider-logo"
                                    />
                                </ProviderLogo>
                                <CustomerDetails>
                                    +91 9539730011@ybl
                                </CustomerDetails>
                            </CustomerInfo>
                        </UPIProviderInfo>
                        <DeleteIconContainer>
                            <img
                                src={
                                    require("../../../assets/images/product-single/delete-icon.svg")
                                        .default
                                }
                                alt="delete-icon"
                            />
                        </DeleteIconContainer>
                    </PaymentCard>
                    <PaymentCard>
                        <UPIProviderInfo>
                            <ProviderName>Phonepe UPI</ProviderName>
                            <CustomerInfo>
                                <ProviderLogo>
                                    <img
                                        src={require("../../../assets/images/product-single/phonepe-logo.png")}
                                        alt="Provider-logo"
                                    />
                                </ProviderLogo>
                                <CustomerDetails>
                                    +91 9539730011@ybl
                                </CustomerDetails>
                            </CustomerInfo>
                        </UPIProviderInfo>
                        <DeleteIconContainer>
                            <img
                                src={
                                    require("../../../assets/images/product-single/delete-icon.svg")
                                        .default
                                }
                                alt="delete-icon"
                            />
                        </DeleteIconContainer>
                    </PaymentCard>
                </PaymentOptionsList>
                <ManageTitle className="card-title">Manage Saved Cards</ManageTitle>
                <PaymentOptionsList>
                    <PaymentCard>
                        <CardInfo>
                            <ProviderLogo>
                                <img
                                    src={require("../../../assets/images/product-single/visa-logo.png")}
                                    alt="Provider-logo"
                                />
                            </ProviderLogo>
                            <CardDetailsInfo>
                                <ProviderName>
                                    Visa Card Ending 458923
                                </ProviderName>
                                <CustomerDetails>
                                    Expiry Date 02/12/2023
                                </CustomerDetails>
                            </CardDetailsInfo>
                        </CardInfo>
                        <DeleteIconContainer>
                            <img
                                src={
                                    require("../../../assets/images/product-single/delete-icon.svg")
                                        .default
                                }
                                alt="delete-icon"
                            />
                        </DeleteIconContainer>
                    </PaymentCard>
                    <PaymentCard>
                        <CardInfo>
                            <ProviderLogo>
                                <img
                                    src={require("../../../assets/images/product-single/visa-logo.png")}
                                    alt="Provider-logo"
                                />
                            </ProviderLogo>
                            <CardDetailsInfo>
                                <ProviderName>
                                    Visa Card Ending 458923
                                </ProviderName>
                                <CustomerDetails>
                                    Expiry Date 02/12/2023
                                </CustomerDetails>
                            </CardDetailsInfo>
                        </CardInfo>
                        <DeleteIconContainer>
                            <img
                                src={
                                    require("../../../assets/images/product-single/delete-icon.svg")
                                        .default
                                }
                                alt="delete-icon"
                            />
                        </DeleteIconContainer>
                    </PaymentCard>
                    <PaymentCard>
                        <CardInfo>
                            <ProviderLogo>
                                <img
                                    src={require("../../../assets/images/product-single/visa-logo.png")}
                                    alt="Provider-logo"
                                />
                            </ProviderLogo>
                            <CardDetailsInfo>
                                <ProviderName>
                                    Visa Card Ending 458923
                                </ProviderName>
                                <CustomerDetails>
                                    Expiry Date 02/12/2023
                                </CustomerDetails>
                            </CardDetailsInfo>
                        </CardInfo>
                        <DeleteIconContainer>
                            <img
                                src={
                                    require("../../../assets/images/product-single/delete-icon.svg")
                                        .default
                                }
                                alt="delete-icon"
                            />
                        </DeleteIconContainer>
                    </PaymentCard>
                </PaymentOptionsList>
            </BottomContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #f8f8f8;
    width: 100%;
    padding: 20px;
    border-radius: 10px;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        background: #fff;
        padding: 0;
        transition: all 0.5s ease-in-out;
        //increasing height when editing input(when save button appears)
        padding-bottom: ${({ isEdit }) =>
            isEdit === null || isEdit === "EMAIL" || isEdit === "CANCEL"
                ? "0"
                : "50px"};
    }

    @media all and (max-width: 360px) {
        padding: 10px;
        padding-bottom: ${({ isEdit }) =>
            isEdit === null || isEdit === "EMAIL" || isEdit === "CANCEL"
                ? "0"
                : "50px"};
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
        padding: 20px;
    }
`;
const TitleBox = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    padding: 20px 10px;
    margin-bottom: 20px;
`;

const LeftArrowBox = styled(Link)`
    cursor: pointer;
    width: 25px;
`;
const PageTitle = styled.h2`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-left: 10px;
    color: #0a0a0a;
`;
const BottomContainer = styled.div`
    @media all and (max-width: 480px) {
        width: 85%;
        margin: 0 auto;
        margin-top: 10px;
    }
`;
const ManageTitle = styled.h5`
    font-family: "dm_sansboldmedium";
    font-size: 18px;
    margin: 0 0 20px;
    &.card-title {
        margin: 20px 0;
    }
`;
const PaymentOptionsList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #f8f8f8;
`;
const PaymentCard = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    @media all and (max-width: 360px) {
        padding: 5px;
    }
    @media all and (max-width: 768px) {
        border-radius: 0px;
    }
`;
const UPIProviderInfo = styled.div``;
const ProviderName = styled.h5`
    font-family: "dm_sansboldmedium";
    font-size: 18px;
    margin-bottom: 10px;
    @media all and (max-width: 980px) {
        font-size: 16px;

    }
`;
const CustomerInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;
const ProviderLogo = styled.div``;
const CustomerDetails = styled.p`
    color: #777a86;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const DeleteIconContainer = styled.div``;
const CardInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;
const CardDetailsInfo = styled.div``;
