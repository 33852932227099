import React, { useContext, useEffect } from "react";

//packages
import styled from "styled-components";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";

//contest
import { Context } from "../../Contexts/Store";

//custom-hook
import useScreenWidth from "../../general/hooks/useScreenWidth";

function SideBar() {
    const {
        state: { account_details },
    } = useContext(Context);

    //hooks
    const naviagte = useNavigate();
    const location = useLocation();
    const screenwidth = useScreenWidth();

    //userdata from store
    const gender = account_details?.gender;
    const firstLettersOfName = account_details?.first_letters_of_name;
    const firstName = account_details?.first_name;
    const lastName = account_details?.last_name;

    // automatically detecting route,screensize and redirecting to "/account/user-profile",
    // if route matches "/account" and if screensize is greater than 768px
    useEffect(() => {
        if (
            screenwidth > 768 &&
            (location.pathname === "/account" || location.pathname === "/account/")
        ) {
            naviagte("/account/user-profile", { replace: true });
        }
    }, [screenwidth]);

    return (
        <>
            <TopContainer>
                <TitleBox>
                    <LeftArrowBox to={"/"}>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/left-arrow.svg"
                            }
                            alt="left-arrow"
                        />
                    </LeftArrowBox>
                    <PageTitle>Profile</PageTitle>
                </TitleBox>
            </TopContainer>
            <MainContainer>
                <UserNameContainer screenwidth={screenwidth}>
                    <UserIcon gender={gender}>
                        <User>
                            {" "}
                            {gender === "male" ? (
                                <img
                                    alt="male"
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/male.svg"
                                    }
                                />
                            ) : gender === "female" ? (
                                <img
                                    alt="male"
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/female.svg"
                                    }
                                />
                            ) : (
                                <span> {firstLettersOfName}</span>
                            )}
                        </User>
                    </UserIcon>

                    <UserName>
                        <WishQuote>Hello</WishQuote>
                        <UserFullName>
                            {firstName} {lastName}{" "}
                        </UserFullName>
                    </UserName>
                </UserNameContainer>

                <UserSecurity
                    screenwidth={screenwidth}
                    to={"/account/user-profile"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <SettingsImageBox>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/profile-security.gif"
                            }
                            alt="privacy"
                        />
                    </SettingsImageBox>

                    <ProfileAndSecurity>
                        <Options>Profile & Security</Options>
                        <Label>Track, Buy things again</Label>
                    </ProfileAndSecurity>
                    <RightArrowBox>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/right-small-arrow.svg"
                            }
                            alt="right-arrow"
                        />
                    </RightArrowBox>
                    <ActiveBox className="active-box"></ActiveBox>
                </UserSecurity>
                <UserSecurity
                    screenwidth={screenwidth}
                    to={"/account/notifications"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <SettingsImageBox>
                        <img
                            src={require("../../../assets/images/product-single/bell-icon.svg").default}
                            alt="privacy"
                        />
                    </SettingsImageBox>

                    <ProfileAndSecurity>
                        <Options>Notifications</Options>
                        <Label>Track, buy things again</Label>
                    </ProfileAndSecurity>
                    <RightArrowBox>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/right-small-arrow.svg"
                            }
                            alt="right-arrow"
                        />
                    </RightArrowBox>
                    <ActiveBox className="active-box"></ActiveBox>
                </UserSecurity>
                <UserSecurity
                    screenwidth={screenwidth}
                    to={"/account/saved-adress"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <SettingsImageBox>
                        <img
                            src={require("../../../assets/images/product-single/home-icon.svg").default}
                            alt="privacy"
                        />
                    </SettingsImageBox>

                    <ProfileAndSecurity>
                        <Options>Saved Adress</Options>
                        <Label>Track, buy things again</Label>
                    </ProfileAndSecurity>
                    <RightArrowBox>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/right-small-arrow.svg"
                            }
                            alt="right-arrow"
                        />
                    </RightArrowBox>
                    <ActiveBox className="active-box"></ActiveBox>
                </UserSecurity>
                <UserSecurity
                    screenwidth={screenwidth}
                    to={"/account/payment-options"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <SettingsImageBox>
                        <img
                            src={require("../../../assets/images/product-single/payment-icon.svg").default}
                            alt="privacy"
                        />
                    </SettingsImageBox>

                    <ProfileAndSecurity>
                        <Options>Payment Options</Options>
                        <Label>Track, buy things again</Label>
                    </ProfileAndSecurity>
                    <RightArrowBox>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/right-small-arrow.svg"
                            }
                            alt="right-arrow"
                        />
                    </RightArrowBox>
                    <ActiveBox className="active-box"></ActiveBox>
                </UserSecurity>
                <UserSecurity
                    screenwidth={screenwidth}
                    to={"/account/contact-us"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <SettingsImageBox>
                        <img
                            src={require("../../../assets/images/product-single/contact-icon.svg").default}
                            alt="privacy"
                        />
                    </SettingsImageBox>

                    <ProfileAndSecurity>
                        <Options>Contact Us</Options>
                        <Label>Track, buy things again</Label>
                    </ProfileAndSecurity>
                    <RightArrowBox>
                        <img
                            src={
                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/right-small-arrow.svg"
                            }
                            alt="right-arrow"
                        />
                    </RightArrowBox>
                    <ActiveBox className="active-box"></ActiveBox>
                </UserSecurity>
            </MainContainer>
        </>
    );
}

export default SideBar;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    display: none;
    margin-bottom: 20px;
    @media all and (max-width: 768px) {
        display: block;
        margin-bottom: 0;
    }
`;
const TitleBox = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    padding: 20px 10px;
    @media all and (max-width: 768px) {
        padding: 20px;
    }
`;

const LeftArrowBox = styled(Link)`
    cursor: pointer;
    width: 25px;
`;
const PageTitle = styled.h2`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    margin-left: 10px;
    color: #0a0a0a;
`;

const MainContainer = styled.div`
    margin-right: 20px;
        display: flex;
    flex-direction: column;
    gap: 15px;
    @media all and (max-width: 768px) {
        width: 100%;
        margin-right: 0px;
        background: #f8f8f8;
    }
`;

const UserNameContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    //hiding box shadow in responsive
    box-shadow: ${({ screenwidth }) =>
        screenwidth > 768 ? "0px 2px 15px 5px rgb(0 0 0 / 7%)" : "none"};

    @media all and (max-width: 768px) {
        width: 100%;
        background: #fff;
        padding: 20px;
    }
`;

const UserIcon = styled.div`
    border-radius: 50%;
    margin-right: 20px;
    min-width: 45px;
    min-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    // span = First letters of username(F-NAME, L-NAME)
    span {
        padding: 6px;
        border: 1px solid #ff5e48;
        min-width: 45px;
        border-radius: ${({ gender }) => (gender !== "" ? "50%" : "")};
        min-height: 45px;
        display: inline-block;
        height: 45px;
        width: 45px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
    }
`;

const User = styled.h2`
    color: #ff5e48;
    font-family: "dm_sansboldbold";
    font-size: 22px;
    text-transform: uppercase;
`;

const UserName = styled.div``;

const WishQuote = styled.h2`
    font-size: 16px;
    font-family: "dm_sansregular";
    color: #282c3f;
`;

const UserFullName = styled.h2`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    color: #0a0a0a;
`;

const UserSecurity = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    border: ${({ screenwidth }) => (screenwidth > 768 ? "1px solid #eeeeee" : "none")};

    @media all and (max-width: 768px) {
        width: 100%;
        background: #fff;
        padding: 20px;
    }
    //hiding highlighted style for non selected tabs(orange-background)
    span.active-box {
        display: none;
    }
    //displaying highlighted style(orange-background) toactive tab(selected tab)
    &.active {
        span.active-box {
            display: block;
        }
    }
`;

const ActiveBox = styled.span`
    position: absolute;
    left: 0;
    z-index: 10;
    width: 10px;
    height: 100%;
    display: inline-block;
    background: #ff5e48;
`;

const SettingsImageBox = styled.div`
    width: 40px;
    margin-right: 15px;
`;

const ProfileAndSecurity = styled.div``;

const Options = styled.h2`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    color: #0a0a0a;
    margin-bottom: 4px;
    @media all and (max-width: 768px) {
        margin-bottom: 0;
    }
`;

const RightArrowBox = styled.span`
    display: inline-block;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
    cursor: pointer;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
    }
`;

const Label = styled.h5`
    color: #5e5e5e;
    font-size: 14px;
    @media all and (max-width: 980px) {
        display: none;
    }
`;
