import React, { useState } from "react";

//packages--------
import styled from "styled-components";

//images----------
import whitetick from "../../../assets/images/gift-product-listing/whitetick.png";
import greentick from "../../../assets/images/gift-product-listing/greentick.png";
import { Link } from "react-router-dom";

export default function ProductFilterPage(showFilterModal, handleFilterModal, setshowFilterModal) {
    const all_filters = [
        {
            id: 1,
            filter_name: "Gender",
        },

        {
            id: 2,
            filter_name: "Categories",
        },

        {
            id: 3,
            filter_name: "Size",
        },

        {
            id: 4,
            filter_name: "Price",
        },

        {
            id: 5,
            filter_name: "Brand",
        },

        {
            id: 6,
            filter_name: "Colour",
        },

        {
            id: 7,
            filter_name: "Discount Range",
        },

        {
            id: 8,
            filter_name: "Bundles",
        },

        {
            id: 9,
            filter_name: "Country of Orgin",
        },

        {
            id: 10,
            filter_name: "More Filters",
        },
    ];

    const gender_list = [
        {
            id: 1,
            gender_items: "Men",
            gender_count: 999,
        },

        {
            id: 2,
            gender_items: "Women",
            gender_count: 999,
        },

        {
            id: 3,
            gender_items: "Boys",
            gender_count: 999,
        },

        {
            id: 4,
            gender_items: "Girls",
            gender_count: 999,
        },
    ];

    // const [filterItems, setFilterItems] = useState(false);

    // const handleFilterItems = () => {
    //     setFilterItems(!filterItems);
    // }
    return (
        <MainContainer>
            <SubContainer>
                {showFilterModal ? (
                    <Overlay onClick={() => handleFilterModal()}></Overlay>
                ) : (
                    ""
                )}
                <BackContainer
                    style={{ transform: showFilterModal && "scale(1,1)" }}
                >
                    <Modal type="textarea">
                        <Container>
                            <TopContainer>
                                <FilterHeading>FILTERS</FilterHeading>
                                <FilterClearHeading>
                                    Clear all
                                </FilterClearHeading>
                            </TopContainer>

                            <BottomContainer>
                                <LeftSideContainer>
                                    <FilterUlContainer>
                                        {all_filters.map((item, index) => (
                                            <FilterLiContainer key={index}>
                                                <FilterLabel>
                                                    {item.filter_name}
                                                </FilterLabel>
                                            </FilterLiContainer>
                                        ))}
                                    </FilterUlContainer>
                                </LeftSideContainer>

                                <RightSideContainer>
                                    <GenderUlContainer>
                                        {gender_list.map((item, index) => (
                                            <GenderLiContainer key={index}>
                                                <GenderTickAndLabelContainer>
                                                    <SelectTickImageContainer>
                                                        <SelectTickImage
                                                            src={whitetick}
                                                        />
                                                    </SelectTickImageContainer>

                                                    <GenderLabels>
                                                        {item.gender_items}
                                                    </GenderLabels>
                                                </GenderTickAndLabelContainer>
                                                <GenderTotalCountLabel>
                                                    {item.gender_count}
                                                </GenderTotalCountLabel>
                                            </GenderLiContainer>
                                        ))}
                                    </GenderUlContainer>
                                </RightSideContainer>
                            </BottomContainer>

                            <ButtonContainer>
                                <CloseButton onClick={() => {
                                    handleFilterModal();
                                }}>CLOSE</CloseButton>
                                <ApplyButton>APPLY</ApplyButton>
                            </ButtonContainer>
                        </Container>
                    </Modal>
                </BackContainer>
            </SubContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding: 20px 0px;
    display: none;

    @media all and (max-width: 480px) {
        display: block;
    }
`;

const SubContainer = styled.div`
    width: 100%;
`;

const Overlay = styled.div`
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    margin: 0 auto;
    right: 0;
    z-index: 1000;
    left: 0;
    top: 19.5%;
`;

const Modal = styled.div`
    background: #fff;
    padding: 15px 0px;
    min-height: 40vh;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    translate: 0 200px;
    transition: 0.5s;
    z-index: 101;
`;

const Container = styled.div``;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
`;

const FilterHeading = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    margin-left: 10px;
`;

const FilterClearHeading = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    color: #f04438;
    cursor: pointer;
    margin-right: 10px;
`;

const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
`;

const LeftSideContainer = styled.div`
    width: 40%;
    background: #f9f9f9;
    min-height: 90vh;
    margin-right: 15px;
`;

const FilterUlContainer = styled.ul``;

const FilterLiContainer = styled.li`
    margin-top: 12px;
    /* margin-bottom: 10px; */
    :hover {
        background: #fff;
    }
`;

const FilterLabel = styled.h2`
    font-size: 16px;
    font-family: "dm_sansregular";
    padding: 10px;

    :hover {
        background: #fff;
    }
`;

const RightSideContainer = styled.div`
    width: 60%;
`;

const GenderUlContainer = styled.ul`
    margin-top: 12px;
`;

const GenderLiContainer = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
`;

const GenderTickAndLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SelectTickImageContainer = styled.div`
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

const SelectTickImage = styled.img`
    width: 100%;
    display: block;
`;

const GenderLabels = styled.h2`
    font-size: 16px;
    font-family: "dm_sansregular";
`;

const GenderTotalCountLabel = styled.h2`
    font-size: 16px;
    font-family: "dm_sansregular";
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    border-top: 1px solid #eeeeee;
    background: #fff;
    padding: 20px;
`;

const CloseButton = styled(Link)`
    font-size: 14px;
    font-family: "dm_sansregular";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;

    ::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 17px;
        right: 240px;
        background-color: #eeeeee;
    }

    :hover {
        color: #f04438;
    }
`;

const ApplyButton = styled.div`
    font-size: 14px;
    font-family: "dm_sansregular";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;

    :hover {
        color: #f04438;
    }
`;
