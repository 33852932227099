import React, { useState } from "react";

//packages-------
import styled from "styled-components";

//images------
import gifImg from "../../../assets/images/gift-images/ShopGiftImg.png";
import { Link } from "react-router-dom";

export default function GiftNewcollection() {
    //gifting section new collection images-------
    const new_collection = [
        {
            id: 1,
            gif_img:
                require("../../../assets/images/gift-new-collection/GiftOne.svg")
                    .default,
        },

        {
            id: 2,
            gif_img:
                require("../../../assets/images/gift-new-collection/GiftTwo.svg")
                    .default,
        },

        {
            id: 3,
            gif_img:
                require("../../../assets/images/gift-new-collection/GiftThree.svg")
                    .default,
        },

        {
            id: 4,
            gif_img:
                require("../../../assets/images/gift-new-collection/GiftFour.svg")
                    .default,
        },

        {
            id: 5,
            gif_img:
                require("../../../assets/images/gift-new-collection/GiftFive.svg")
                    .default,
        },

        {
            id: 6,
            gif_img:
                require("../../../assets/images/gift-new-collection/GiftSix.svg")
                    .default,
        },
    ];

    const [hover, setHover] = useState(1);
    return (
        <MainContainer>
            <SubContainer className="wrapper">
                <Container>
                    <TopContainer>
                        <Title>
                            <LabelBold>New </LabelBold>Collection{" "}
                            <Text>Gift's</Text>
                        </Title>
                    </TopContainer>

                    <BottomContainer>
                        <NewCollectionContainer>
                            {new_collection.map((item, index) => (
                                <NewCollections
                                    key={index}
                                    onMouseOver={() => setHover(item.id)}
                                    onMouseOut={() => setHover("")}
                                >
                                    <NewCollectionImages
                                        src={item.gif_img}
                                        alt="New Collection Images"
                                    />

                                    {hover == item.id ? (
                                        <ShopContainer to="/gift-listing">
                                            <ShopNowLabel>
                                                Shop Now
                                            </ShopNowLabel>
                                            <GiftImage>
                                                <GiftImg src={gifImg} />
                                            </GiftImage>
                                        </ShopContainer>
                                    ) : null}
                                </NewCollections>
                            ))}
                        </NewCollectionContainer>
                    </BottomContainer>
                </Container>
            </SubContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding-bottom: 45px;
`;

const SubContainer = styled.div``;

const Container = styled.div``;

const TopContainer = styled.div`
    margin-bottom: 25px;
`;

const Title = styled.h3`
    font-size: 28px;
    font-family: "dm_sansboldMedium";

    @media all and (max-width: 640px) {
        font-size: 24px;
    }
`;

const LabelBold = styled.span`
    font-size: 28px;
    font-family: "dm_sansboldbold";

    @media all and (max-width: 640px) {
        font-size: 24px;
    }
`;

const Text = styled.span`
    font-size: 28px;
    color: #ff5e48;
    font-family: "dm_sansboldMedium";

    @media all and (max-width: 640px) {
        font-size: 24px;
    }
`;

const BottomContainer = styled.div`
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.12);
    padding: 25px;
    border-radius: 20px;

    @media all and (max-width: 768px) {
        padding: 15px;
        border-radius: 15px;
    }
`;

const NewCollectionContainer = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const NewCollections = styled.li`
    width: 32%;
    margin-bottom: 20px;
    position: relative;

    &:nth-child(4) {
        margin-bottom: 0;

        @media all and (max-width: 480px) {
            margin-bottom: 15px;
        }
    }

    &:nth-child(5) {
        margin-bottom: 0;

        @media all and (max-width: 480px) {
            margin-bottom: 15px;
        }
    }

    &:nth-child(6) {
        margin-bottom: 0;
    }

    @media all and (max-width: 980px) {
        width: 31%;
    }

    @media all and (max-width: 768px) {
        margin-bottom: 15px;
    }

    @media all and (max-width: 480px) {
        width: 100%;
    }
`;

const NewCollectionImages = styled.img`
    width: 100%;
    display: block;
`;

const ShopContainer = styled(Link)`
    position: absolute;
    top: 42%;
    height: 50px;
    width: 100%;
    background: #ffffff60;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    transition: 0.4s ease;
    cursor: pointer;
`;

const ShopNowLabel = styled.h2`
    font-size: 18px;
    color: #ffff;
    font-family: "dm_sansboldmedium";
`;

const GiftImage = styled.div`
    filter: invert(100);
`;

const GiftImg = styled.img``;
