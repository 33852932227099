import React, { useContext } from "react";
import { Context } from "../../Contexts/Store";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
    const {
        state: { user_data },
    } = useContext(Context);

    return user_data?.access_token ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
