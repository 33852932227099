import React from "react";

//packages
import { Route, Routes } from "react-router-dom";

//components
import GiftLandingPage from "../../screens/gift/GiftLandingPage";
import GiftProductListing from "../../screens/GiftProductListing";
import ProductSinglepage from "../../screens/ProductSinglepage";

function ProductRouter() {
    console.log("helloo");
    return (
        <>
            <Routes>
                <Route path="/" element={<GiftLandingPage />} />
                <Route path="/products" element={<GiftProductListing />} />
                <Route
                    path="/products/products_id"
                    element={<ProductSinglepage />}
                />
            </Routes>
        </>
    );
}

export default ProductRouter;
