import React, { useState } from "react";

//packages--------
import styled from "styled-components";
import Slider from "react-slick";

//components-----
import ProductSortPage from "../../includes/SortAndFilter/ProductSortPage";
import ProductFilterPage from "../../includes/SortAndFilter/ProductFilterPage";
import { Link } from "react-router-dom";

//slider components-----
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//images----
import wishlistwhite from "../../../assets/images/gift-product-listing/likebuttonwhite.png";
import wishlistred from "../../../assets/images/gift-product-listing/likebutton.png";

export default function GiftListingPage() {
    const product_list = [
        {
            id: 1,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 2,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 3,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 4,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 5,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 6,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 7,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 8,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 9,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 10,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 11,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },

        {
            id: 12,
            image_url: require("../../../assets/images/gift-product-listing/WatchOne.svg"),
            image_url_one: require("../../../assets/images/gift-product-listing/watchtwo.svg"),
            image_url_two: require("../../../assets/images/gift-product-listing/watchthree.svg"),
            proudct_name: "Huami Amazefit",
            product_title: "Watch, Black",
            product_current_price: "Rs.12000",
            product_og_price: "Rs.17000",
            product_discount: "(76 % off)",
        },
    ];

    //for product sorting----
    const [showSortList, setShowSortList] = useState(false);

    const handleSortModal = () => {
        setShowSortList(!showSortList);
    };

    //for product filter----
    const [showFilterModal, setShowFilterModal] = useState(false);

    const handleFilterModal = () => {
        setShowFilterModal(!showFilterModal);
    };

    //slider settings-----
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
    };

    const [isWhislisted, setIsWishlisted] = useState("");

    const handleWishlist = (id) => {
        setIsWishlisted(id);
    };

    const [hover, setHover] = useState("");

    console.log(hover, "====ajmal====");
    return (
        <>
            <MainContainer>
                <SubContainer>
                    <RightSideContainer>
                        <ProductDetailsContainer>
                            {product_list.map((item, index) => (
                                <ProductAndDiscriptonContainer key={index}>
                                    {/* <Slider {...settings}> */}
                                    <ProductImageContainer
                                        onMouseEnter={() => setHover(item.id)}
                                    >
                                        <ProductImage
                                            src={item.image_url.default}
                                            alt="watch image"
                                        />
                                    </ProductImageContainer>

                                    {/* <ProductImageContainer>
                                            <ProductImage
                                                src={item.image_url_one.default}
                                                alt="watch two"
                                            />
                                        </ProductImageContainer>

                                        <ProductImageContainer>
                                            <ProductImage
                                                src={item.image_url_two.default}
                                                alt="watch three"
                                            />
                                        </ProductImageContainer>
                                        </Slider> */}

                                    {hover === item.id ? (
                                        <WishlistContainer
                                            onClick={() => {
                                                handleWishlist(item.id);
                                            }}
                                        >
                                            <WishlistBox
                                                wishlisted={
                                                    isWhislisted === item.id
                                                }
                                            >
                                                {isWhislisted === item.id ? (
                                                    <WislistLabel
                                                        wishlisted={
                                                            isWhislisted ===
                                                            item.id
                                                        }
                                                    >
                                                        Wishlisted
                                                    </WislistLabel>
                                                ) : (
                                                    <WislistLabel>
                                                        WishList
                                                    </WislistLabel>
                                                )}

                                                <WishListImageContainer>
                                                    {isWhislisted ===
                                                    item.id ? (
                                                        <WishlistImage
                                                            src={wishlistred}
                                                            alt="like button red"
                                                        />
                                                    ) : (
                                                        <WishlistImage
                                                            src={wishlistwhite}
                                                            alt="like button white"
                                                        />
                                                    )}
                                                </WishListImageContainer>
                                            </WishlistBox>
                                        </WishlistContainer>
                                    ) : (
                                        ""
                                    )}

                                    <ProductNameLabel>
                                        {item.proudct_name}
                                    </ProductNameLabel>
                                    <ProductCategory>
                                        {item.product_title}
                                    </ProductCategory>
                                    <ProductPrizeContainer>
                                        <ProductCurrentPrize>
                                            {item.product_current_price}
                                        </ProductCurrentPrize>
                                        <ProductOrginalPrize>
                                            {item.product_og_price}
                                        </ProductOrginalPrize>
                                        <ProductOfferLabel>
                                            {item.product_discount}
                                        </ProductOfferLabel>
                                    </ProductPrizeContainer>
                                </ProductAndDiscriptonContainer>
                            ))}
                        </ProductDetailsContainer>
                    </RightSideContainer>
                </SubContainer>
            </MainContainer>

            <SortAndFilterContainer>
                <SortButtonContainer
                    onClick={() => {
                        handleSortModal();
                    }}
                >
                    <SortIconContainer>
                        <SortIcon
                            src={
                                require("../../../assets/images/gift-product-listing/sortImage.svg")
                                    .default
                            }
                        />
                    </SortIconContainer>
                    <SortLabel>Sort</SortLabel>
                </SortButtonContainer>

                <ProductSortPage
                    showSortList={showSortList}
                    setShowSortList={setShowSortList}
                    handleSortModal={handleSortModal}
                />

                <FilterButtonContainer
                    onClick={() => {
                        handleFilterModal();
                    }}
                >
                    <FilterIconContainer>
                        <FilterIcon
                            src={
                                require("../../../assets/images/gift-product-listing/filterImage.svg")
                                    .default
                            }
                        />
                    </FilterIconContainer>
                    <FilterLabel>Filter</FilterLabel>
                </FilterButtonContainer>
            </SortAndFilterContainer>

            {showFilterModal && (
                <ProductFilterPage
                    showFilterModal={showFilterModal}
                    setShowFilterModal={setShowFilterModal}
                    handleFilterModal={handleFilterModal}
                />
            )}
        </>
    );
}

const RightSideContainer = styled.div`
    width: 100%;
    /* margin-top: 15px;
    margin-left: 15px; */
    padding: 20px 0px 0px 20px;

    @media all and (max-width: 1080px) {
        width: 65%;
    }

    @media all and (max-width: 980px) {
        width: 60%;
    }

    @media all and (max-width: 768px) {
        width: 45%;
    }

    @media all and (max-width: 480px) {
        width: 100%;
        margin-top: 0px;
    }
`;

const ProductDetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    padding-bottom: 35px;

    @media all and (max-width: 1150px) {
        grid-gap: 25px 15px;
    }

    @media all and (max-width: 1080px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media all and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media all and (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const MainContainer = styled.div`
    @media all and (max-width: 480px) {
        width: 96%;
    }
`;

const SubContainer = styled.div``;

const BottomContainer = styled.div``;

const BottomSection = styled.div`
    display: flex;
    margin-left: 25px;
    @media all and (max-width: 480px) {
        border-top: unset;
    }
`;

const MiddleSection = styled.div`
    border-right: 1px solid #eeeeee;
    margin-right: 25px;

    @media all and (max-width: 980px) {
        margin-right: 17px;
    }

    @media all and (max-width: 480px) {
        display: none;
    }
`;

const ProductAndDiscriptonContainer = styled.li`
    margin-bottom: 20px;
    position: relative;
`;

const ProductImageContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;

const ProductImage = styled.img`
    width: 100%;
    display: block;
`;

const WishlistContainer = styled.div`
    position: absolute;
    bottom: 75px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 0px 0px 4px 4px;
    cursor: pointer;
    backdrop-filter: blur(5px);
`;

const WishlistBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px 15px;
    width: 100%;
    background: ${(props) => (props.wishlisted ? "#fff" : "")};
`;

const WislistLabel = styled.h1`
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    color: ${(props) => (props.wishlisted ? "#000" : "#fff")};
    margin-right: 10px;
`;

const WishListImageContainer = styled.div`
    width: 20px;
`;

const WishlistImage = styled.img`
    width: 100%;
    display: block;
`;

const ProductImageContainerTwo = styled.div``;

const ProductNameLabel = styled.h1`
    color: #282c3f;
    font-size: 16px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const ProductCategory = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-bottom: 5px;
`;

const ProductPrizeContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ProductCurrentPrize = styled.h1`
    color: #282c3f;
    font-size: 14px;
    font-family: "dm_sansboldmedium";
    margin-right: 5px;

    @media all and (max-width: 1150px) {
        font-size: 12px;
    }

    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const ProductOrginalPrize = styled.span`
    display: inline-block;
    color: #777a86;
    font-size: 12px;
    font-family: "dm_sansregular";
    margin-right: 5px;
    position: relative;

    @media all and (max-width: 480px) {
        font-size: 10px;
    }

    ::before {
        content: "";
        display: block;
        width: 50px;
        height: 1px;
        background-color: #777a86;
        position: absolute;
        top: 8px;

        @media all and (max-width: 480px) {
            width: 40px;
            top: 6px;
        }
    }
`;

const ProductOfferLabel = styled.h3`
    color: #ff5e48;
    font-size: 12px;
    font-family: "dm_sansregular";

    @media all and (max-width: 1150px) {
        font-size: 12px;
    }

    @media all and (max-width: 480px) {
        font-size: 10px;
    }
`;

const SortAndFilterContainer = styled.div`
    display: none;

    @media all and (max-width: 480px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        position: fixed;
        bottom: 0;
        background: #fff;
        width: 100%;
        min-height: 12%;
    }
`;

const SortButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);
    padding: 12px 40px;
    width: 45%;
    margin: 0 auto;
`;

const SortIconContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`;

const SortIcon = styled.img`
    width: 100%;
    display: block;
`;

const SortLabel = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
`;

const FilterButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);
    padding: 12px 40px;
    width: 45%;
    margin: 0 auto;
`;

const FilterIconContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`;

const FilterIcon = styled.img`
    width: 100%;
    display: block;
`;

const FilterLabel = styled.h1`
    font-size: 16px;
    font-family: "dm_sansboldmedium";
`;
